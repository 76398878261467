import React from 'react';

const PotableSidebar = () => {

    let data = [
        { sidebarListLink: "/potablewater/", sidebarListIcon: 'fa fa-question', sidebarListText: 'Potable Water Tanks Cathodic Protection' },
        { sidebarListLink: "/potablewater/100kgallon", sidebarListIcon: 'fa fa-question', sidebarListText: '100,000 Gallon' },
        { sidebarListLink: "/potablewater/1mgallon", sidebarListIcon: 'fa fa-question', sidebarListText: '1,000,000 Gallon' },
        { sidebarListLink: "/potablewater/3mgallon", sidebarListIcon: 'fa fa-question', sidebarListText: '3,000,000 Gallon' },
    ];

    let Datalist = data.map((val, i) => {
        return (
            <li key={i}><a href={val.sidebarListLink}><i className={val.sidebarListIcon} />{val.sidebarListText}</a></li>
        )
    })

    return (
        <div>
            <div className="sidebar-wrapper">
                <div className="sidebar">
                    <h3 className="sidebar-title">Potable Water Storage</h3>
                    <ul className="sidebar-list">
                        {Datalist}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PotableSidebar;