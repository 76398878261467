import React from 'react';

import NavBar from '../../components/Navbar';
import MobileMenu from '../../components/MobileMenu';

import HeroSlider from './HeroSlider';
import FeatureIconText from './FeatureIconText';
import AboutText from './AboutText';
import ServiceTabTwo from './ServiceTabTwo';
import NewsBanner from './NewsBanner'
import ProjectImageGallery from './ProjectImageGallery'
import BrandLogoSlider from './BrandLogoSlider';
import Footer from '../../components/Footer';

const Home = () => {
    return (
        <div>
            {/* Navigation bar */}
            <NavBar />

            {/* Hero slider */}
            <HeroSlider />

            {/* Feature icon text */}
            <FeatureIconText />

            {/* Feature banner*/}
            <NewsBanner />

            {/* About text */}
            <AboutText />

            {/* Service Tab */}
            <ServiceTabTwo />

            {/* Project image gallery */}
            <ProjectImageGallery background="grey-bg" />

            {/* Brand logo */}
            <BrandLogoSlider />

            {/* Footer */}
            <Footer />

            {/* Mobile Menu */}
            <MobileMenu />

        </div>
    )
}

export default Home;