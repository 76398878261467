import React, { Component } from 'react';

class USTSidebar extends Component{
    
    render(){

        let data = [
            {sidebarListLink: "/ust/corrosionbasics", sidebarListIcon: 'flaticon-015-cart', sidebarListText: 'Corrosion Basics'},
            {sidebarListLink: "/ust/understandingcathodicprotection", sidebarListIcon: 'flaticon-010-tank-1', sidebarListText: 'Understanding Cathodic Protection'},
            {sidebarListLink: "/ust/interpretingvoltage", sidebarListIcon: 'flaticon-002-welding', sidebarListText: 'Interpreting Voltage Potential Readings'},
            {sidebarListLink: "/ust/electricalgrounding", sidebarListIcon: 'flaticon-004-walkie-talkie', sidebarListText: 'Electrical Grounding'},
            {sidebarListLink: "/ust/steelpipe", sidebarListIcon: 'flaticon-042-monitor', sidebarListText: 'Steel Pipe & Bare or Coated Tanks'},
            {sidebarListLink: "/ust/fiberglasslinedtanks", sidebarListIcon: 'flaticon-042-monitor', sidebarListText: 'Fiberglass Lined Tanks'},
            {sidebarListLink: "/ust/stiptanks", sidebarListIcon: 'flaticon-042-monitor', sidebarListText: 'STIP Tanks'},
            {sidebarListLink: "/ust/steelswingjoints", sidebarListIcon: 'flaticon-042-monitor', sidebarListText: 'Steel Swing Joints & Flex Connectors'},
            {sidebarListLink: "/ust/timesavingtips", sidebarListIcon: 'flaticon-042-monitor', sidebarListText: 'Time Saving Tips'},
        ];

        let USTDatalist = data.map((val, i)=>{
            return(
                <li key={i}><a href={val.sidebarListLink}><i className={val.sidebarListIcon} />{val.sidebarListText}</a></li>
            )
        })

        return(
            <div>
                <div className="sidebar-wrapper">
                    <div className="sidebar">
                        <h3 className="sidebar-title">UST Content and Topics</h3>
                        <ul className="sidebar-list">
                            {USTDatalist}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default USTSidebar;