import React, { Component } from "react";

import NavBar from "../../components/Navbar";
import FeatureIcon from "../../pages/Contact/FeatureIcon";
import TeamMemberGrid from "../../pages/Contact/TeamMemberGrid";
import BrandLogoSlider from "../../pages/HomePage/BrandLogoSlider";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu";
import { Container, Row, Col, Button } from "react-bootstrap";

class MeetTheTeam extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
    }

    render() {
        const { history } = this.props;

        return (
            <div>
                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area ">
                    <Container>
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <ul className="page-breadcrumb">
                                        <li>
                                            <a href="/">Home</a>
                                        </li>
                                        <li>Meet The Team</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--30 pt-5">
                    {/*About section start*/}
                    <div className="about-section section-space--inner--30">
                        <Container>
                            <Row className="row-25 align-items-center">
                                <div className="offset-lg-3 col-lg-8 col-12 mb-30">
                                    <div className="about-content-two">
                                        <h3>Welcome to Anode Systems Co.</h3>
                                        <h1>
                                            35+ Years of Experience in Industry
                                        </h1>
                                        <h4>
                                            Let us help you stay in compliance
                                        </h4>
                                        <p>
                                            Anode Systems specialize in surface
                                            bed and deep anode bed impressed
                                            current and galvanic anode systems
                                            on pipelines, storage tanks, and
                                            other steel structures. We offer a
                                            variety of pressure vessel and tank
                                            anodes for the oil and gas industry,
                                            and all materials and accessories to
                                            the cathodic protection systems are
                                            manufactured and supplied in bulk at
                                            our on-site warehouse, providing our
                                            clients with the highest quality
                                            galvanic/sacrificial anodes and
                                            impress current anode systems
                                            nationwide.
                                        </p>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                    {/*About section end*/}

                    {/* Feature Icon */}
                    <FeatureIcon background="grey-bg" />

                    {/*About section start*/}
                    <div className="about-section section-space--inner--60">
                        <Container>
                            <div className="about-wrapper row">
                                <div className="col-sm-6 col-12 order-1 order-lg-3">
                                    <div className="about-image about-image-1">
                                        <img
                                            className="w-100"
                                            src="/assets/img/about/about.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 order-2 order-lg-1">
                                    <div className="about-image about-image-2">
                                        <img
                                            className="w-100"
                                            src="/assets/img/services/ust/UST-1.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 order-3 order-lg-2">
                                    <div className="about-content about-content-1">
                                        <h1>
                                            <span>35+</span>Years of Experience
                                        </h1>
                                        <p>
                                            Working as a team with our clients,
                                            Anode Systems Company, is dedicated
                                            to preserving our clients’
                                            investments by protecting essential
                                            man-made infrastructure and systems
                                            through corrosion prevention,
                                            cathodic protection, and education.
                                            Anode Systems is dedicated in caring
                                            for the environment, via the
                                            prevention of leaks and/or failures
                                            of tanks and pipes. We are committed
                                            to excellence– providing trusted
                                            experience, knowledge, and premium
                                            products at a competitive price to
                                            our customers coast to coast.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 order-4">
                                    <div className="about-content about-content-2">
                                        <p>
                                            Our Specialty is CP of tanks and
                                            pipes. Our reports are written to be
                                            understood by laymen in language
                                            they can understand.
                                        </p>
                                        <Button
                                            variant="primary"
                                            className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                                            onClick={() => {
                                                history.push("/contactus");
                                            }}
                                        >
                                            CONTACT US
                                        </Button>
                                        {/* <a href={`${process.env.PUBLIC_URL}/contactus`} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Contact us</a> */}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    {/*About section end*/}

                    {/* Team member */}
                    <TeamMemberGrid />

                    {/* Brand logo */}
                    <BrandLogoSlider background="" />
                </div>

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />
            </div>
        );
    }
}

export default MeetTheTeam;
