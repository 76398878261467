import React from 'react';
import { Row, Col } from 'react-bootstrap';

const AllAboutMagnesiumAnodes = () => {

    return (
        <Col xs={12} className="section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">All About Magnesium Anodes</h2>

                <p>Magnesium anodes come in all shapes and sizes. The magnesium in a packaged anode weighs from 1 pound to 50 pounds. The most commonly used anode in the United States is a 17 pound anode. With 28 pounds of powdered backfill packed around the magnesium, a packaged 17 pound anode weighs about 45 pounds. It comes with 10 feet or 15 feet of #12, insulated solid copper wire. The backfill consists of 75% powdered gypsum, 20% powdered bentonite clay and 5% sodium sulfate. This mixture is a low cost, non-hazardous, electrically conductive earth backfill. A cotton bag holds the backfill around the inner metal bar. It decomposes over time in the ground. The anode is shipped in an outer disposable, plastic bag to protect it from rain or snow. It is recommended that 5 gallons of water be poured on the anode after it is placed in the earth to wet the backfill. This lowers the resistance between the magnesium and the earth causing more electric current to flow. This provides more protective current than would be possible in dry soil.</p>
                <div className="col-10 offset-1 project-image py-3">
                    <img src="/assets/img/services/education/allaboutmagnesiumanodes.jpg" className="img-fluid" alt="" />
                </div>
                <p>To illustrate what was said above, assume that a new tank is installed and protected with magnesium anodes. As long as a combined voltage of at least -0.85 volts can be measured all around the tank, it meets the criteria for corrosion protection. If anodes are attached to an old pitted tank, and the voltage shifted from -0.50 volts to at least -0.85 volts, the pits should not become any deeper and the tank would be protected from further corrosion. Therefore, if corrosion has damaged a tank but not caused it to leak, cathodic protection can be installed and the tank does not have to be replaced.</p>
                <p>Due to the difference in the oxygen content of the soil around the tank compared to the less oxygen in the undisturbed earth under the tank, corrosive conditions will exist under the tank. If any of the answers to the above questions are "yes" then the tank will be corroded.</p>
                <p>This also applies to buried piping. Because the voltage of underground structures cannot be measured with absolute accuracy over 100% of the surface area, generalizations are made. It is not possible for corrosion engineers to guarantee complete protection over every square inch of the tank's surface area.</p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/propane/whatiscorrosion">01</a></li>
                        <li><a href="/propane/whatmakessoilcorrosive">02</a></li>
                        <li><a href="/propane/whatiscathodicprotection">03</a></li>
                        <li className="active"><a href="/propane/allaboutmagnesiumanodes">04</a></li>
                        <li><a href="/propane/awordaboutcoatings">05</a></li>
                        <li><a href="/propane/whatiselectricalisolation">06</a></li>
                        <li><a href="/propane/howtoinstallananode">07</a></li>
                        <li><a href="/propane/testingforcorrosionprotection">08</a></li>
                        <li><a href="/propane/references">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/propane/whatiscathodicprotection">
                            <i className="fa fa-angle-left" /> Prev</a>
                        </li>
                        <li><a href="/propane/awordaboutcoatings">
                            <i className="fa fa-angle-right" /> Next</a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Col>
    )
}


export default AllAboutMagnesiumAnodes;