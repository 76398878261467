import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
const HeaterTreater = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Heater Treater and Marine/Aqueous Anodes</h2>

                <p>Popular in the oil and gas industry for use in various pressure vessels and tanks, the high potential aluminum alloy is a galvanic/sacrificial anode to be consumed in the cathodic protection process under extreme heat and highly corrosive saltwater/brine environments (available in 3”x 30” and 3”x 60”)</p>
                <br />
                <Container className="section-space--top--60">
                    <Row>
                        <Col xs={5} className="project-image py-3">
                            <img src="/assets/img/services/installations/aluminum.png" className="img-fluid" alt="" />
                        </Col>
                        <Col xs={7}>
                            <h2>Redhead and Bluehead Alumnium Anodes</h2>
                            <br />
                            <p>This high potential aluminum alloy is designed for easy installation and replacement when inserted into a Victaulic style tank nozzle and secured with a Victaulic coupling, securing the anode assembly to the nozzle.</p>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <div className="col-12 col-lg-4">
                            <img className="w-100" src="/assets/img/services/installations/bluehead.png" alt=""></img>
                        </div>
                        <div className="col-12 col-lg-4">
                            <img className="w-100" src="/assets/img/services/installations/bluehead2.jpg" alt=""></img>
                        </div>
                        <div className="col-12 col-lg-4">
                            <img className="w-100" src="/assets/img/services/installations/bluehead3.jpg" alt=""></img>
                        </div>
                    </Row>
                </Container>
                <Container className="section-space--top--60">
                    <Row>
                        <div className="col-12 col-lg-5 project-image py-3"><img src="/assets/img/services/installations/flanged.png" className="img-fluid" alt="" /></div>
                        <div className="col-12 col-lg-7">
                            <h2>Blind Flange Mount Aluminum Anodes</h2>
                            <br />
                            <p>This high potential aluminum alloy is designed for easy installation and replacement when inserted into a Victaulic style tank nozzle and secured with a Victaulic coupling, securing the anode assembly to the nozzle.</p>
                        </div>

                    </Row>
                </Container>
                <Container className="section-space--top--60">
                    <Row>
                        <Col xs={12}>
                            <h2>Cable Suspension Aluminum Anodes</h2>
                            <br />
                            <p>These are connected to the roof of the tank or vessel, the anode and cable is securely capped to reduce the stress at the anode-to-cable connection point.</p>
                        </Col>
                        <Row className="section-space--top--60">
                            <div className="col-12 col-lg-6">
                                <img className="w-100" src="/assets/img/services/installations/hanger.jpg" alt=""></img>
                            </div>
                            <div className="col-12 col-lg-6">
                                <img className="w-100" src="/assets/img/services/installations/hanger2.jpg" alt=""></img>
                            </div>
                        </Row>
                    </Row>
                </Container>
            </div>
        </div>
    )
}


export default HeaterTreater;