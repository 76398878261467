import React from 'react';
import { Col, Container } from 'react-bootstrap'

const Anodes = () => {

    return (
        <Col xs={12} className="section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <Container>
                    <h2 className="py-5 text-center">Anodes</h2>
                    <Col>
                        <p className="text-center">Anode Systems Company is a leading anode supplier nationwide. Our on site manufacturing and distribution warehouse managed by licensed and NACE certified engineers, develop innovative anode designs exclusive to Anode Systems Co., manage custom orders, and provides expedited shipping coast to coast. Quality manufacturing of each anode is hand-crafted, like that of our bagged magnesium anodes: special attention is paid toward centering the anode around compact and uniform backfill–free of voids. The quality control surrounding the production of our anodes prolongs the life of the anode and promotes its efficiency–producing a reliable current via exact moisture content.</p>
                    </Col>
                    <br />
                    <Col>
                        <img className="w-100" src="/assets/img/services/education/anodes-1.jpg" alt="" />
                    </Col>
                    <br />
                    <Col>
                        <p>The 10 ft solid copper lead wire is carefully inspected for defects in the coating and then compactly rolled in a tapered design to prevent tangling when unwound. The tightly coiled wire also protects against damage to neighboring packed anodes or oneself when handling during transport. The specially engineered cotton bag is virtually indestructible and will not tear under the combined weight of the anode and backfill.</p>
                    </Col>
                    <br />
                    <Col>
                        <img className="w-100" src="/assets/img/services/education/anodes-2.jpg" alt="" />
                    </Col>
                    <br />
                    <Col>
                        <p>Galvanic Anodes - Also known as sacrificial anodes, come in many different shapes, sizes, and metallic makeup relative to their function. The galvanic anode is best served for smaller steel structures like that of a single residential propane tank, heater treater, pressure vessel, or boat hull.</p>
                    </Col>
                    <br />
                    <Col>
                        <img className="w-100" src="/assets/img/services/education/anodes-3.jpg" alt="" />
                    </Col>
                    <br />
                    <Col>
                        <p>Anode Systems Company offers an H-1 magnesium alloy, “high potential” 99% magnesium alloy, zinc, cast iron, and aluminum anodes. The most popular of these types is the 17lb high potential magnesium anode because of its longer life due to its size and ability to power through resistive soil. These anodes are “consumed” in the cathodic protection process and are sacrificed for the sake of the steel tank or pipe.</p>
                    </Col>
                    <br />
                    <Col>
                        <img className="w-100" src="/assets/img/services/education/anodes-4.png" alt="" />
                    </Col>
                    <br />
                    <Col>
                        <p>Impressed Current Anodes - These systems are designed for larger surface areas like that of a commercial grade storage tank or pipeline to provide a uniform current throughout. Rectifiers aide in this capacity by connecting to the power grid, providing 110 volts of electricity to the anode system buried below. The DC current can then be adjusted via a dial (above ground) for optimal and precise current output.</p>
                    </Col>
                    <br />
                    <Col>
                        <img className="w-100" src="/assets/img/services/education/anodes-5.jpg" alt="" />
                    </Col>
                    <br />
                    <Col>
                        <p>Impressed current anodes are made of a special alloy of cast iron, silicon and chromium and packed tightly in a coke breeze backfill. Maintenance on this system is recommended once a year by a CP professional.</p>
                    </Col>
                    <br />
                    <Col>
                        <img className="w-100" src="/assets/img/services/education/anodes-6.jpg" alt="" />
                    </Col>
                </Container>
            </div>
        </Col>
    )
}

export default Anodes;