import React from 'react'
import { withRouter } from "react-router-dom"
import { Container, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons"

import './Topbar.css'

const Topbar = ({ history }) => {

    return (
        <React.Fragment>
            <div className="header-top-bar">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={4}>
                            <div className="top-bar-left-wrapper">
                                <div className="social-links social-links--white-topbar d-inline-block align">
                                    <ul>
                                        <li>
                                            <a href="//facebook.com/anodesystemsco"><FontAwesomeIcon icon={faFacebookF} size='lg' /></a>
                                        </li>
                                        <li>
                                            <a href="//youtube.com/channel/UCIACRlEU0Lfz4vaM2vUqNbw"><FontAwesomeIcon icon={faYoutube} size='lg' /></a>
                                        </li>
                                        <li>
                                            <a href="//instagram.com/anodesystemscompany"><FontAwesomeIcon icon={faInstagram} size='lg' /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="top-bar-right-wrapper py-2">
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        history.push("/contactus")
                                    }}>
                                    <strong>GET A QUOTE</strong>
                                </Button>
                                {/* 
                                {" "}
                                {!isAuthenticated() && (
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            history.push("/login")
                                        }}
                                    > <strong>LOGIN/REGISTER</strong>
                                    </Button>
                                )}
                                {" "}
                                {isAuthenticated() && isAuthenticated().user.role === 0 && (
                                    <Button
                                        onClick={() => {
                                            history.push('/user/dashboard')
                                        }}
                                        variant="primary"
                                        className="ht-btn ht-btn-default d-inline-block"
                                    > USER DASHBOARD
                                    </Button>
                                )}
                                {" "}
                                {isAuthenticated() && isAuthenticated().user.role === 1 && (
                                    <Button
                                        onClick={() => {
                                            history.push('/admin/dashboard')
                                        }}
                                        variant="primary"
                                        className="ht-btn ht-btn-default d-inline-block"
                                    > ADMIN DASHBOARD
                                    </Button>
                                )}
                                {" "}
                                {isAuthenticated() && (
                                    <Button
                                        variant="primary"
                                        className="ht-btn ht-btn--default d-inline-block"
                                        onClick={() => {
                                            signout(() => {
                                                history.push("/");
                                            })
                                        }}
                                    > SIGNOUT
                                    </Button>
                                )} */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* =======  End of header top bar  ======= */}
        </React.Fragment >
    )
}

export default withRouter(Topbar)