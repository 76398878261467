import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import NavBar from '../components/Navbar';
import MobileMenu from '../components/MobileMenu';

import ServiceSidebar from '../components/Sidebar/ServiceSidebar';
import OilSidebar from '../components/Sidebar/OilSidebar';

import OilHome from '../pages/Services/oilgas/OilHome'

import ProducedWaterTankProgram from '../pages/Services/education/ProducedWaterTankProgram';
import LeaksCanBeCostly from '../pages/Services/education/LeaksCanBeCostly';
import PurposeAndScope from '../pages/Services/education/PurposeAndScope';
import FinalizedAmendment from '../pages/Services/education/FinalizedAmendment';

import BrandLogoSlider from '../pages/HomePage/BrandLogoSlider';
import Footer from '../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';

class OilGasServiceDetails extends Component {
    render() {
        const { match } = this.props
        return (
            <div>
                <NavBar />
                <div className="breadcrumb-area">
                    <Container>
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href='#0'>Services</a></li>
                                        <li>Oil & Gas</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--60">
                    {/*Service section start*/}
                    <div className="service-section">
                        <Container>
                            <Row>
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <div className="service-details">
                                        <div className="content section-space--top--30">
                                            <Row>
                                                <Col xs={12}>
                                                    <Switch>
                                                        <Route exact path={`${match.path}`} component={OilHome} />
                                                        <Route exact path={`${match.path}/producedwatertankprogram`} component={ProducedWaterTankProgram} />
                                                        <Route exact path={`${match.path}/leakscanbecostly`} component={LeaksCanBeCostly} />
                                                        <Route exact path={`${match.path}/purposeandscope`} component={PurposeAndScope} />
                                                        <Route exact path={`${match.path}/finalizedamendment`} component={FinalizedAmendment} />
                                                    </Switch>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <ServiceSidebar />
                                    <br />
                                    <OilSidebar />
                                </div>
                            </Row>
                        </Container>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Brand logo */}
                <BrandLogoSlider background="grey-bg" />

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div >
        )
    }
}


export default OilGasServiceDetails;