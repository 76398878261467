import React from 'react';
import { Row, Col } from 'react-bootstrap'

const SteelSwingJoints = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Steel Swing Joints & Flex Connectors</h2>
                <p>At those locations where fiberglass tanks and piping is used, the only steel components that may need cathodic protection are the steel swing joints and stainless steel flex connectors. If cathodic protection is required on these steel segments, magnesium anodes may not work. Because of the electrical connection of these pipes to the electric wires and conduit, drive- in stake anodes may not raise the pipe-to-soil potential readings to -0.85 volts.</p>
                <div className="col-10 offset-1 project-image py-5"><img src="/assets/img/services/ust/UST-6.jpg" className="img-fluid" alt="" /></div>
                <p>There are several reasons why low voltage readings are measured on STIP tanks. The most often cause is from metal conduit that rubs against the edge of the riser bung. Over time, the conduit rubs the coating off the sharp edge of the bung and makes metal to metal contact causing an electrical short.</p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/education/corrosionbasics">01</a></li>
                        <li><a href="/ust/understandingcathodicprotection">02</a></li>
                        <li><a href="/ust/interpretingvoltage">03</a></li>
                        <li><a href="/ust/electricalgrounding">04</a></li>
                        <li><a href="/ust/steelpipe">05</a></li>
                        <li><a href="/ust/fiberglasslinedtanks">06</a></li>
                        <li><a href="/ust/stiptanks">07</a></li>
                        <li className="active"><a href="/ust/steelswingjoints">08</a></li>
                        <li><a href="/ust/timesavingtips">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/stiptanks"><i className="fa fa-angle-left" /> Prev</a></li>
                        <li><a href="/ust/timesavingtips"><i className="fa fa-angle-right" /> Next</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default SteelSwingJoints;