import React from 'react';
import { Row, Col } from 'react-bootstrap'
const References = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">References</h2>
                <p>If you would like more information on corrosion, cathodic protection, or regulations concerning underground tanks, the following publications are recommended:</p>
                <div className="project-image pb-3"><img src="/assets/img/services/education/references.jpg" className="img-fluid" alt="" /></div>
                <a href="https://dokumen.tips/documents/api-rp-1632.html">API Publication 1632, "Cathodic Protection of Underground Petroleum Storage Tanks and Piping Services", American Petroleum Institute, Washington D.C., 1996</a>
                <br />
                <br />
                <a
                    href="https://store.nace.org/corrosion-prevention-by-protective-coatings-third-edition">Corrosion Prevention by Protective Coatings, Charles G. Munger, NACE, Houston, TX 1984</a>
                <br />
                <br />
                <a
                    href="https://archive.org/stream/ControlOfPipelineCorrosion/ControlOfPipelineCorrosion_djvu.txt">Control of Pipeline Corrosion, A.W. Peabody, NACE, Houston, TX 1967</a>
                <br />
                <br />
                <a
                    href="https://www.nace.org/uploadedFiles/Corrosion_Central/Industries/SP028511%20for%20State%20of%20New%20York.pdf">NACE Standard RP0285-95 "Corrosion Control of Underground Storage Tank Systems by Cathodic Protection", NACE, Houston, TX 1995</a>
                <br />
                <br />
                <a
                    href="https://www.nace.org/uploadedfiles/corrosion_central/industries/sp016907phmsa.pdf">NACE Standard RP0169-92, "Control of External Corrosion on Underground or submerged Metallic Piping Systems", NACE, Houston, TX 1992</a>
                <br />
                <br />
                <a
                    href="https://studylib.net/doc/18652820/installation-of-underground-lp-gas-systems">NPGA #152-91, "Corrosion Protection for Underground LP-Gas Systems", National Propane Gas Association, Lisle, IL 1994</a>
                <br />
                <br />
                <a
                    href="https://studylib.net/doc/18652820/installation-of-underground-lp-gas-systems">NPGA #412-94, "Installation of Underground LP-Gas Systems", National Propane Gas Association, Lisle, IL 1994</a>
                <br />
                <br />
                <a
                    href="https://catalog.nfpa.org/NFPA-54-National-Fuel-Gas-Code-Handbook-P15719.aspx">NFPA 54, "National Fuel Gas Code Handbook", National Fire Protection Association, Quincy, MA 1996</a>
                <br />
                <br />
                <a
                    href="https://www.nfpa.org/Assets/files/AboutTheCodes/58/NFPA_58-1995.pdf">NFPA 58, "Standard for the Storage and Handling of Liquified Petroleum Gases", National Fire Protection Association, Quincy, MA 1995</a>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/propane/whatiscorrosion">01</a></li>
                        <li><a href="/propane/whatmakessoilcorrosive">02</a></li>
                        <li><a href="/propane/whatiscathodicprotection">03</a></li>
                        <li><a href="/propane/allaboutmagnesiumanodes">04</a></li>
                        <li><a href="/propane/awordaboutcoatings">05</a></li>
                        <li><a href="/propane/whatiselectricalisolation">06</a></li>
                        <li><a href="/propane/howtoinstallananode">07</a></li>
                        <li><a href="/propane/testingforcorrosionprotection">08</a></li>
                        <li className="active"><a href="/propane/references">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/propane/testingforcorrosionprotection"><i className="fa fa-angle-left" /> Prev</a></li>
                        <li><a href="/propane"><i className="fa fa-angle-right" /> Next</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default References;