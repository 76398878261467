import React from 'react'
import { Row, Col } from 'react-bootstrap'

const USTHome = () => {
    return (
        <div className="service-details">

            <div className="content section-space--top--30 text-center">
                <Row>
                    <Col xs={12}>
                        <h2>UST</h2>
                        <p>Steel UST systems can be protected from rusting by applying cathodic protection. Without cathodic protection, current flows from the surface of the steel tank into the ground and the tank rusts. With cathodic protection, a current flows to the steel tank and the tank is protected from rusting.</p>
                        <br />
                        <img src="assets/img/services/ust/UST-1.jpg" className="img-fluid" alt="" />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default USTHome