import React, { useState, useEffect, Fragment } from 'react'

import NavBar from '../../components/Navbar'
import Footer from '../../components/Footer'

import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight, faHome, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { PDFReader } from 'reactjs-pdf-reader'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    }
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}


const Chipeta2020Resources = () => {
    const [currentFile, setCurrentFile] = useState(`assets/pdf/ChipetaSlides.pdf`)
    const [pageNumber, setPageNumber] = useState(1)

    let data = [
        { title: "Presentation Slides", url: `${process.env.PUBLIC_URL}/assets/pdf/ChipetaSlides.pdf` },
        { title: "Program Proceedings", url: `${process.env.PUBLIC_URL}assets/pdf/ProgramScan.pdf` },
        { title: "Didn't Have to Happen", url: "assets/pdf/didnthavetohappen.pdf" },
        { title: "2020 Lease Tank Draft", url: "assets/pdf/600SeriesDraft.pdf" },
        { title: "2010 SPCC", url: "assets/pdf/SPCCRules.pdf" },
        { title: "Material Performance Article", url: "assets/pdf/MPArticle.pdf" },
        { title: "Anode Pictures and Information", url: "assets/pdf/SeminarProducts.pdf" },
    ]

    const ShowFile = () => {
        const { width } = useWindowDimensions()
        return (
            <>
                <div style={{ overflow: 'scroll' }}>
                    <Row>
                        <PDFReader url={currentFile} width={width / 2} page={pageNumber} />
                    </Row>
                    <Row className="mt-4 paginationContainer">
                        <button className="mr-1" onClick={() => (setPageNumber(pageNumber - 1))}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} size={'lg'} />
                        </button>
                        <button className="mr-1" onClick={() => (setPageNumber(0))}>
                            <FontAwesomeIcon icon={faHome} size={'lg'} />
                        </button>
                        <button className="ml-1" onClick={() => (setPageNumber(pageNumber + 1))}>
                            <FontAwesomeIcon icon={faChevronCircleRight} size={'lg'} />
                        </button>
                    </Row>
                </div>
            </>
        )
    }

    let dataList = data.map((val, i) => {

        return (
            <ListGroup.Item action className="d-flex justify-content-between" title={val.title} url={val.url} key={i} onClick={() => { setCurrentFile(val.url) }}>
                {val.title}
                <span><FontAwesomeIcon icon={faChevronRight} /></span>
            </ListGroup.Item>
        )
    })

    return (
        <Fragment>
            <NavBar />
            <Container>
                <Row>
                    <Col className="my-5">
                        <h3>2020 Steel Lease Tank Seminar/Webinar</h3>
                        <p>Please find some resources below, they will be referenced in the webinar.</p>
                        <Row>
                            <Col md={4}>
                                <Card>
                                    <Card.Header>
                                        <h4>Resources</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        {dataList}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={8}>
                                <Card >
                                    <Card.Body>
                                        <ShowFile />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
            <Footer />
        </Fragment>
    )
}

export default Chipeta2020Resources
