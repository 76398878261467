import React from 'react'
import { Link } from 'react-router-dom'


const InstallationSidebar = (props) => {

    const links = [
        { url: "/installations/pipeline", icon: 'fa fa-pipe', title: 'Pipeline' },
        { url: "/installations/heatertreater", icon: 'fa fa-question', title: 'Heater Treaters' },
        { url: "/installations/AST", icon: 'fa fa-question', title: 'AST' },
        { url: "/installations/UST", title: 'UST' },
    ].map((link) => {
        return (
            <li key={link.url}>
                
                <Link to={link.url}>{link.title}</Link>
            </li>
        )
    })

    return (
        <div>
            <div className="sidebar-wrapper">
                <div className="sidebar">
                    <h3 className="sidebar-title">Installations</h3>
                    <ul className="sidebar-list">
                        {links}
                    </ul>
                </div>
            </div>
        </div>
    )
}


export default InstallationSidebar;