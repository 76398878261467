import React from 'react';
import { Row, Col } from 'react-bootstrap';

const FiberglassLinedTanks = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Fiberglass Lined Tanks</h2>
                <p>How does a tester distinguish between a fiberglass tank and a fiberglass lined tank when lowering a contact down through the fill pipe to the bottom of the tank? The solution is to check the continuity between the fill pipe and the submersible pump. If there is little or no resistance between them, the tank is made of steel. If there is over 200 ohms of resistance, the tank is made of fiberglass.</p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/corrosionbasics">01</a></li>
                        <li><a href="/ust/understandingcathodicprotection">02</a></li>
                        <li><a href="/ust/interpretingvoltage">03</a></li>
                        <li><a href="/ust/electricalgrounding">04</a></li>
                        <li><a href="/ust/steelpipe">05</a></li>
                        <li className="active"><a href="/ust/fiberglasslinedtanks">06</a></li>
                        <li><a href="/ust/stiptanks">07</a></li>
                        <li><a href="/ust/steelswingjoints">08</a></li>
                        <li><a href="/ust/timesavingtips">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/steelpipe"><i className="fa fa-angle-left" /> Prev</a></li>
                        <li><a href="/ust/stiptanks"><i className="fa fa-angle-right" /> Next</a></li>
                    </ul>
                </Col>
            </Row>
        </div >
    )
}


export default FiberglassLinedTanks;