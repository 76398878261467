export default {
    name: 'Anode Systems Co',
    fullName: 'Anode Systems Co - Experienced Cathodic Protection Services',
    url: '',
    author: {
        name: 'Abel',
    },
    contacts: {
        address: '124 N. 22nd Court Unit D',
        address2: 'Grand Junction, CO 81505 USA',
        email: 'info@anodesystems.com',
        tollfree: '(888) 609-9766',
        office: '(970) 243-4149',
        fax: '(970) 263-7295',
    },
    hours: 'Mon- Fri 7:00am - 5:00pm (MST)',
};
