import React, { Component } from 'react';

import ImageGallery from 'react-image-gallery'

import 'react-image-gallery/styles/css/image-gallery.css'
import { Row, Col } from 'react-bootstrap';

class Tank3M extends Component {
    render() {
        const images = [
            {
                original: '/assets/img/services/potable/3m_1.jpg',
                description: 'Northerly view of both tanks showing solar panel on top of the three million gallon water tank at the vent in the center.'
            },
            {
                original: '/assets/img/services/potable/3m_2.jpg',
                description: 'The anode and solar panel wires run in conduit across the top and down the side of the tank.'
            },
        ]

        return (

            <div className="col-12 order-1 order-lg-2">
                <div className="service-details">
                    {/* service gallery */}
                    {/* <PotableServiceGallery /> */}


                    <ImageGallery items={images} />
                    <div className="content section-space--top--30">
                        <Row>
                            <Col xs={12}>
                                <h2 className="py-2 text-center">3 Million Gallon Potable Water Storage Tanks</h2>
                                <p>Anode Systems offers Cathodic Protection Services which include design, installation, and materials for water storage tanks or any kind of tank. Anode Systems is a trusted supplier since 1985.</p>
                                <p>Steel water storage tanks will corrode and leak if unprotected against corrosion from chlorinated water on the inside and from the earth on the outside. Anode Systems Company has developed an innovative solution to installing internal corrosion protection anode systems. Fourteen systems have been installed in Colorado, Utah and Wyoming at substantial cost savings to the water districts. The unique feature of the floating and floor mounted anodes is that they are not susceptible to damage from ice forming on the top of the water in the winter. Older systems in which anodes were hung from the ceiling either had to be removed in the fall or risk being pulled down by the ice in the winter. Either system takes only one or two days to install.</p>
                                <p>Anode Systems Company is engineering new and improved methods for cathodically protecting water storage tanks from corrosion. The three systems illustrated in the following examples are much cheaper to install than conventional systems. They can be leased to make the installation cost affordable to small water districts.</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}


export default Tank3M;