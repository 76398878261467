import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap'

class IrrigationPhotoGallery extends Component {
    render() {

        return (

            <div>
                <div className="service-gallery">
                    <Row>
                        <Col xs={6}>
                            <img src="assets/img/services/irrigation/1_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                        <Col xs={6}>
                            <img src="assets/img/services/irrigation/2_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <img src="/assets/img/services/irrigation/3_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                        <Col xs={6}>
                            <img src="/assets/img/services/irrigation/4_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <img src="/assets/img/services/irrigation/5_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                        <Col xs={6}>
                            <img src="/assets/img/services/irrigation/6_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <img src="/assets/img/services/irrigation/8_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                        <Col xs={6}>
                            <img src="/assets/img/services/irrigation/9_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <img src="/assets/img/services/irrigation/10_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                        <Col xs={6}>
                            <img src="/assets/img/services/irrigation/11_irr.jpg" className="img-fluid" alt=""></img>
                        </Col>
                    </Row>
                </div>
            </div>

        )
    }
}

export default IrrigationPhotoGallery;