/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import NavBar from '../components/Navbar';
import MobileMenu from '../components/MobileMenu';
import EducationSidebar from '../components/Sidebar/EducationSidebar';
import EducationHome from '../pages/Services/education/EducationHome';
import BrandLogoSlider from '../pages/HomePage/BrandLogoSlider';
import Footer from '../components/Footer';

import UnderstandingCorrosion from '../pages/Services/education/UnderstandingCorrosion';
import Anodes from '../pages/Services/education/Anodes'
import Videos from '../pages/Services/education/Videos'

class EducationServiceDetails extends Component {
    state = { numPages: null };

    onDocumentLoadSuccess = (document) => {
        const { numPages } = document
        this.setState({ numPages })
    }


    render() {

        const { match } = this.props

        return (
            <div>
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area">
                    <Container>
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a>Services</a></li>
                                        <li>Education</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--60">
                    {/*Service section start*/}
                    <div className="service-section">
                        <Container>
                            <Row>
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <div className="service-details">
                                        <div className="content section-space--top--30">
                                            <Row>
                                                <Col xs={12}>
                                                    <Switch>
                                                        <Route exact path={`${match.path}`} component={EducationHome} />
                                                        <Route exact path={`${match.path}/understandingcorrosion`} component={UnderstandingCorrosion} />
                                                        <Route exact path={`${match.path}/anodes`} component={Anodes} />
                                                        <Route exact path={`${match.path}/videos`} component={Videos} />
                                                    </Switch>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <EducationSidebar />
                                </div>
                            </Row>
                        </Container>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Brand logo */}
                <BrandLogoSlider background="grey-bg" />

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div >
        )
    }
}


export default EducationServiceDetails;