import React from 'react';
import { Link } from 'react-router-dom';

const PropaneSidebar = (props) => {

    const links = [
        { title: 'Home', url: '/propane/', icon: 'fa fa-home' },
        { title: 'What is Corrosion?', url: '/propane/whatiscorrosion', icon: 'fa fa-question-circle' },
        { title: 'What Makes Soil Corrosive?', url: '/propane/whatmakessoilcorrosive', icon: 'fa fa-question-circle' },
        { title: 'What Is Cathodic Protection?', url: '/propane/whatiscathodicprotection', icon: 'fa fa-question-circle' },
        { title: 'All About Magnesium Anodes', url: '/propane/allaboutmagnesiumanodes', icon: 'fa fa-question-circle' },
        { title: 'A Word About Coatings', url: '/propane/awordaboutcoatings', icon: 'fa fa-question-circle' },
        { title: 'What Is Dielectric Strength and Electrical Isolation', url: '/propane/whatiselectricalisolation', icon: 'fa fa-question-circle' },
        { title: 'How To Install An Anode', url: '/propane/howtoinstallananode', icon: 'fa fa-question-circle' },
        { title: 'Testing For Corrosion Protection', url: '/propane/testingforcorrosionprotection', icon: 'fa fa-question-circle' },
        { title: 'References', url: '/propane/references', icon: 'fa fa-book' }
    ].map((link) => {
        
        const url = `${link.url}`;

        return (
            <li key={link.url}>
                <Link to={url}><i className={link.icon}></i>{link.title}</Link>
            </li>
        )
    })

    return (
        <div>
            <div className="sidebar-wrapper">
                <div className="sidebar">
                    <h3 className="sidebar-title">FAQS</h3>
                    <ul className="sidebar-list">
                        {links}
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default PropaneSidebar;