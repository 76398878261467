import React from 'react';
import { Link } from 'react-router-dom'

const OilSidebar = () => {


    const links = [
        { url: "/oilgas", icon: 'fa fa-question', title: 'Home' },
        { url: "/oilgas/producedwatertankprogram", icon: 'fa fa-question', title: 'Produced Water Tank Protection Program' },
        { url: "/oilgas/leakscanbecostly", icon: 'fa fa-question', title: 'Leaks Can Be Costly' },
        { url: "/oilgas/purposeandscope", icon: 'fa fa-question', title: 'Purpose & Scope' },
        { url: "/oilgas/finalizedamendment", icon: 'fa fa-question', title: 'Finalized Amendments to SPCC' },
    ].map((link) => {
        return (
            <li key={link.url}>
                <Link to={link.url}><i className={link.icon} />{link.title}</Link></li>
        )
    })

    return (
        <div>
            <div className="sidebar-wrapper">
                <div className="sidebar">
                    <h3 className="sidebar-title">FAQS</h3>
                    <ul className="sidebar-list">
                        {links}
                    </ul>
                </div>
            </div>
        </div>
    )
}


export default OilSidebar;