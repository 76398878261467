import React from 'react';

const PurposeAndScope = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h3>Purpose of the Program</h3>
                <br />
                <p>To eliminate replacement costs of leaking produced water tanks that are approximately $10,000 per tank.</p>
                <p>To eliminate cleanup costs of contaminated soil.</p>
                <p>To eliminate cleanup costs to contaminated water.</p>
                <p>To prevent violations of the Clean Water Act or violations of drilling permits that provide for the proper disposal of waste water.</p>
                <p>To prevent the cost of fines or legal expenses and the costs of future leaks.</p>
                <h3>Losses To The Producer Caused By Internal Corrosion Of Produced Water Tanks</h3>
                <p>Produced water can cause internal corrosion of a steel tank resulting in a failure and a replacement of the tank.</p>
                <p>Produced water can contain salt, benzene, hydrocarbons or hazardous fluids that are detrimental to the soil under the tank. The cost to excavate and aerate contaminated soil and the possible transportation and disposal of contaminated soil could be significant.</p>
                <p>A leak of sufficient quantity could migrate down to a shallow water table or to a surface stream causing harm to groundwater supplies or to vegetation or to wildlife. The cost of drilling de-watering wells, installing storage facilities and disposing of contaminated water could have a high initial cost and be a monthly expense for years.</p>
                <p>Replacement costs for materials and labor for a new storage tank could easily exceed $10,000.</p>
                <p>Cleanup costs for a tank that has leaked over a period of time could easily exceed $5,000.</p>
                <p>Legal costs to defend against a landowner lawsuit could cost thousands of dollars.</p>
                <p>A government agency fine could exceed $1 million.</p>
                <h3>Purpose Of The Program</h3>
                <p>In 1972 Congress passed the Water Pollution Control Act (also known as the Clean Water Act) to prevent discharges of oil from vessels and other facilities both onshore and offshore. The Clean Water Act made the EPA responsible for storage facilities and it made the DOT responsible for transportation facilities. It also resulted in the first Spill Prevention, Control and Countermeasure (SPCC) regulations.</p>
                <p>The Oil Pollution Prevention regulation known as the SPCC Regulation was enacted in 1974 to protect surface waters. The regulation is known as Title 40, Code of Federal Regulations (CFR) Part 112. It requires owners or operators of storage facilities (non transportation) to have a plan to prevent, control, manage and remove oil spills under a worst case scenario.</p>
                <p>In 1976 Congress passed the Resource Conservation and Recovery Act (RCRA) in response to environmental catastrophes such as the Love Canal in Niagara Falls, New York. It directed the EPA to develop and implement a program to protect human health and the environment from improper hazardous waste management practices by big companies. The program was designed to control the management of hazardous waste from its generation to its ultimate disposal (cradle to grave).</p>
                <p>In 1984, President Reagan signed into law the Hazardous and Solid Waste Amendments to RCRA. This act of Congress directed the EPA to establish new requirements that would bring small generators of hazardous waste from leaking fuel and chemical tanks into the regulatory system. This was to protect drinking water supplies from fuel or chemicals that could seep into the earth and contaminate the ground water that is a source of drinking water. The law is known as 40 CFR Part 280 and went into effect in 1988. Among other rules, the law requires cathodic protection on any underground steel tank with a capacity of 500 gallons or more.</p>
                <p>In 1988 a 4 million gallon oil storage tank collapsed at Ashland Oil Company’s Floreffe, PA refinery which spilled 750,000 gallons of diesel fuel over the tank’s containment dike, across a parking lot on an adjacent property and into a storm drain that emptied into the Monongahela River. The oil slick flowed for miles down river, over two dam locks and into the Ohio River. It killed wildlife, damaged private property, adversely affected businesses in the area, contaminated river ecosystems and temporarily contaminated the drinking water for an estimated one million people in Pennsylvania, West Virginia and Ohio.</p>
                <p>In 1989 the oil tanker, Exxon Valdez ran onto rocks and spilled 10,000,000 gallons of crude oil into the Prince William Sound of Alaska. The Exxon Valdez spill moved Congress to enact the Oil Pollution Act of 1990. The Oil Pollution Act of 1990 made oil spill prevention, control, response and removal a major priority of the Clean Water Act.</p>
                <p>In 1994 the U.S. Government enacted new laws regulating above ground petroleum and chemical tanks as a result of the spillage of 750,000 gallons of oil into the Monongahela River by Ashland Oil below Pittsburgh.</p>
                <p>In 2001 Chevron agreed to pay fines to the EPA totaling $750,000 and make $3 million worth of improvements for spilling the equivalent of 90,000 barrels of salt water and oil over a period of five years from corroded pipes in its Rangely field. The fine was for 320 salt water spills and 36 oil spills from leaks caused by corrosion. Chevron acknowledged that the majority of spills were on dry land but that some salt water and oil reached the White River.</p>
                <p>In 2005 the Colorado Liquefied Petroleum (LPG) rule (7 CCR 1101-15) went into effect. Among other requirements, the law requires corrosion protection and monitoring of all new tanks installed after September 1, 2005 and of all existing underground LPG tanks by January 1, 2011.</p>
                <p>In May 2009, Anadarko Petroleum agreed to pay more than $1 million in fines and to spend more than $8 million on improvements to its SPCC program. This was in response to at least 35 spills of more than 31,300 barrels of oily water over a five year period between 2003 and 2008. The oily water and oil were discharged into drainages in Wyoming that are tributaries to the Clarks Fork and the Powder Rivers.</p>
                <p>On June 19, 2009, the EPA published in the Federal Register a SPCC compliance date extension for oil production facilities until November 10, 2010. In November 2009 the EPA signed a notice covering the December 5, 2008, amendments to the SPCC rule. It changed the SPCC rule that had exempted produced water tanks at oil production facilities. Effective January 14, 2010, produced water tanks are not exempt from SPCC rules as they once were. SPCC plans must be amended and the plans must be implemented by November 10, 2010.</p>
                <p>In 2010 the National Fire Protection Association Code on underground LPG tanks is being revised. The 2011 Edition of NFPA 58 will require cathodic protection and periodic testing of all new underground steel LPG tanks.</p>
                <h3>Purpose Of The Program</h3>
                <p>In 1998 the EPA estimated that 25% of all underground steel tanks were probably leaking through holes caused by corrosion. In 1998 the EPA stated that after ten years, tanks and pipes not protected against corrosion had a dramatically higher potential for a failure due to corrosion.</p>
                <p>There are 75,000 oil or gas wells in Colorado. At a 25% failure rate and one water tank per well, 18,750 tanks could be leaking.</p>
                <p>A drip of one drop every two seconds through a pinhole leak will amount to 438 gallons in one year.</p>
                <p>A stream of water through a 1/16 in. hole in a tank will amount to 25,920 gallons in one year.</p>
                <p>A stream of water through a ¼ in. hole in a tank will amount to 335,232 gallons in one year.</p>
                <p>Earthen berms around produced water tanks setting on the ground will not contain the produced water lost through a hole in the bottom of the tank. The water will seep into the ground under the tank. Water from a leaking tank where there is no shallow groundwater will evaporate leaving the salts and oily hydrocarbons in the soil. Water from a leaking tank where there is a high water table will commingle with the groundwater and has the potential for surfacing in nearby surface waters.</p>
            </div>
        </div>
    )
}

export default PurposeAndScope;