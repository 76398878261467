import React from 'react';

const UnderstandingCorrosion = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Understanding Corrosion</h2>
                <p>“Rusting” or corrosion, is the natural tendency of a refined manmade metal (tank or pipe) wanting to revert to its original and more stable state in the form of a salt and iron oxide (rust). The pathway of corrosion requires a cell: a cathode, an anode, an electrolyte (like water) and a metallic path. This “corrosion cell” can occur on the same manmade metal structure in localized areas in the form of “pitting” or crevice corrosion, occurring where the coating may be defective. Pitting can be very deceptive; the deterioration of a relatively small area can ultimately cause massive failure of a tank or pipe. Galvanized corrosion occurs when two dissimilar metals (like a copper pipe attached to a steel tank) couple together in an electrolytic environment, causing one metal to become the anode and corrode, and the other metal to become the protected cathode.</p>
                <img className="col-8 offset-2 py-4" src="/assets/img/services/education/Education-1.jpg" alt=""></img>
                <p>All metals have stored energy in the form of electrons, distinct to their nature- magnesium with the most, followed by zinc and aluminum, and finally steel and cast iron. Lead, brass and copper are low energy thus only mildly affected by corrosion with carbon metal and gold finishing last. These stored electrons travel along the pathway from higher energy to lower energy- much like water flow, creating an electric current. Installing a high energy metal magnesium bar (anode) forces the lower energy steel tank/pipeline (cathode) to accept electrons, thus preventing oxidization (corrosion.) This installation of an anode is called cathodic protection.</p>
                <img className="col-8 offset-2 py-4" src="/assets/img/services/education/anodes-2.jpg" alt=""></img>
                <p>The chemical component of corrosion involves the combination of water ions, oxygen and other negatively charged ions with positively charged iron to form iron oxide which we call rust. Dissolved salt and other minerals in water accelerate the rate of corrosion. When a tank corrodes, the iron atoms break away from the steel surface. Over time, this leaves pits in the steel which eventually become holes. Corrosion occurs at an anode. Therefore, pits occur at anodic spots on the surface. Protection occurs at a cathode. Therefore, the unpitted areas are cathodic spots.</p>
                <img className="col-8 offset-2 py-4" src="/assets/img/services/education/anodes-3.jpg" alt=""></img>
                <p>The most commonly installed anode consists of a 17lb. bar of magnesium metal packed inside a cloth bag containing 28 lbs. of tightly packed and uniform conductive earth backfill with an insulated copper wire that is attached to both the magnesium bar and the tank/pipe. The magnesium bar is a source of high energy (voltage) electrons with an open circuit potential of -1.80 volts. The steel structure to be protected is around -.50 volts. When the two metals are connected via the copper wire, this creates a direct current (DC current) that can be measured above ground and recorded. A good voltmeter reading would be an equalized average of the two at about -.85 volts or a greater negative, confirming cathodic protection.</p>
                <img className="col-8 offset-2 py-4" src="/assets/img/services/education/Education-4.jpg" alt=""></img>
            </div>
        </div>
    )
}

export default UnderstandingCorrosion;