import React from "react";

import NavBar from "../../components/Navbar";
import MobileMenu from "../../components/MobileMenu";
import Footer from "../../components/Footer";

import { Row, Container, Col } from "react-bootstrap";

const PageNotFound = () => {
    return (
        <div>
            {/* Navigation bar */}
            <NavBar />

            {/*====================  404 page content ====================*/}
            <div>
                <div className="error-page-wrapper d-flex align-items-center">
                    <Container>
                        <Row>
                            <Col lg={6} md={9} className="m-auto text-center">
                                <div className="error-content-centered d-flex align-items-center justify-content-center">
                                    <div className="error-page-content-wrap">
                                        <h2>404</h2>
                                        <h3>PAGE NOT FOUND</h3>
                                        <p>
                                            Sorry but the page you are looking
                                            for does not exist, have been
                                            removed, name changed or is
                                            temporarily unavailable.
                                        </p>
                                        <a
                                            href="/"
                                            className="ht-btn ht-btn--default ht-btn--default--dark-hover"
                                        >
                                            Back to Home Page
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/*====================  End of 404 page content  ====================*/}

            {/* Footer */}
            <Footer />

            {/* Mobile Menu */}
            <MobileMenu />
        </div>
    );
};

export default PageNotFound;
