import React from 'react';
import { Row, Col } from 'react-bootstrap'

const WhatMakesSoilCorrosive = () => {

    return (
        <div>
            <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
                <div className="project-details">
                    <h2 className="py-5 text-center">What Makes Soil Corrosive?</h2>
                    <Col md={4} />
                    <p>Soil contains water and dissolved minerals responsible for damaging an underground tank. All soil contains water. Even desert sand contains underground moisture.Let's assume that you are responsible for deciding whether or not corrosion will affect a new tank to be installed at a new home in the desert. First, let’s begin with the questions:</p>
                    <img src="/assets/img/services/education/whatmakessoilcorrosive.jpg" className="img-fluid text-center pb-3" alt="" />
                    <p>Will it ever rain or snow there? Will the new owner ever plant grass, flowers, shrubs or trees over or near the tank? Will ground moisture condense on the outside surface of the tank? Will water condense out of thin air on the surface of a cold container? Will the grass, flowers or shrubs over the tank be fertilized? Will the tank ever be near a sidewalk or down gradient from a driveway? Will the owner ever throw salt or calcium chloride on the pavement to melt snow and ice? Will magnesium chloride ever be used to control road dust nearby? Will the tank ever be located near or downhill from livestock where salt and urea from animal waste will leach into the earth? Does the irrigation water contain salt or alkali salts? If a tank is set in a hole blasted from solid rock, does the rock contain pyrites, graphite or acidic rocks that will be fractured and exposed to the oxidizing effect of air and water?</p>
                    <p>Pyrite (iron sulfide) exists in black shale, coal, limestone, metamorphic rocks, in mining districts and in oxygen deficient mud. It oxidizes into iron oxide which appears as rust staining and a mild sulfuric acid. Graphite (carbon) is corrosive to iron as previously mentioned. It is found in metamorphic rocks of New England.</p>
                    <p>If the tank is backfilled with sand or gravel from a sand and gravel company, will the sand contain minerals mined from river deposits not representative of the site in which the tank is buried? Will the tank be downhill from a septic tank leach field? Whatever flows into the septic tank will be deposited in the ground below the drain field. Will the soil contain gypsum? Anaerobic bacteria eat gypsum (calcium sulfate) and produce sulfide wastes that in turn becomes sulfuric acid. Will the tank be set in shale or clay? Will groundwater be present? Will the tank be set on undisturbed earth and backfilled with loose backfill?</p>
                    <p>Due to the difference in the oxygen content of the soil around the tank compared to the less oxygen in the undisturbed earth under the tank, corrosive conditions will exist under the tank. If any of the answers to the above questions are "yes" then the tank will be corroded.</p>
                </div>
                <Row>
                    <Col>
                        <ul className="page-pagination section-space--top--30">
                            <li><a href="/propane/whatiscorrosion">01</a></li>
                            <li className="active"><a href="/propane/whatmakessoilcorrosive">02</a></li>
                            <li><a href="/propane/whatiscathodicprotection">03</a></li>
                            <li><a href="/propane/allaboutmagnesiumanodes">04</a></li>
                            <li><a href="/propane/awordaboutcoatings">05</a></li>
                            <li><a href="/propane/whatiselectricalisolation">06</a></li>
                            <li><a href="/propane/howtoinstallananode">07</a></li>
                            <li><a href="/propane/testingforcorrosionprotection">08</a></li>
                            <li><a href="/propane/references">09</a></li>
                        </ul>
                        <ul className="page-pagination section-space--top--30">
                            <li><a href="/propane/whatiscorrosion"><i className="fa fa-angle-left" /> Prev</a></li>
                            <li><a href="/propane/whatiscathodicprotection"><i className="fa fa-angle-right" /> Next</a></li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </div >
    )
}

export default WhatMakesSoilCorrosive;