import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { Row, Container, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faNodeJs, faReact } from '@fortawesome/free-brands-svg-icons';

// import { ReactComponent as Logo } from '../../assets/img/logo/anodesystems-light.svg';
import { ReactComponent as Logo } from '../../../public/assets/img/logo/anodesystems-light.svg';
import './Footer.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.mount = false;
    }

    handleScroll() {
        if (this.mount) {
            this.setState({ scroll: window.scrollY });
        }
        console.log(scroll.scrollTo);
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.mount = true;

        const el = document.querySelector('nav');
        this.setState({ top: el.offsetTop, height: el.offsetHeight });
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        this.state.scroll > this.state.top
            ? document.getElementById('scroll-top').classList.add('show')
            : document.getElementById('scroll-top').classList.remove('show');
    }

    componentWillUnmount() {
        this.mount = false;
    }

    render() {
        return (
            <div>
                {/*====================  footer area ====================*/}
                <div className="footer-area dark-bg">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="footer-content-wrapper section-space--inner--30 text-center">
                                    <Row>
                                        <div className="offset-lg-1 col-lg-4 col-md-12">
                                            {/* footer intro wrapper */}
                                            <div className="footer-intro-wrapper">
                                                <div className="footer-logo">
                                                    <Link to={'/'}>
                                                        <Logo />
                                                    </Link>
                                                    <div className="footer-desc text-center">
                                                        WE SOLVE CORROSION
                                                        PROBLEMS.
                                                        <br />
                                                        WE CONDUCT SEMINARS,
                                                        SHORT COURSES & CLASSES
                                                        NATIONWIDE ON CORROSION
                                                        CONTROL, CATHODIC
                                                        PROTECTION, MAINTENANCE
                                                        OF CP SYSTEMS &
                                                        TROUBLESHOOTING.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            {/* footer widget */}
                                            <div className="footer-widget">
                                                <h3 className="footer-widget__title">
                                                    USEFUL LINKS
                                                </h3>
                                                <ul className="footer-widget__navigation">
                                                    <li>
                                                        <Link to={'/'}>
                                                            Home
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <a href="//anodesalesco.com">
                                                            Materials
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <Link to={'/contactus'}>
                                                            Contact Us
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 text-center">
                                            {/* footer widget */}
                                            <div className="footer-widget mb-0">
                                                <h3 className="footer-widget__title">
                                                    CONTACT US
                                                </h3>
                                                <div className="footer-widget__content">
                                                    <p className="address">
                                                        124 N. 22nd Court <br />{' '}
                                                        Grand Junction, CO 81501
                                                    </p>
                                                    <ul className="contact-details">
                                                        <li>
                                                            <span>P:</span>(970)
                                                            243-4149
                                                        </li>
                                                        <li>
                                                            <span>F:</span>(970)
                                                            263-7295
                                                        </li>
                                                        <li>
                                                            <span>T:</span>(888)
                                                            609-9766
                                                        </li>
                                                        <li>
                                                            <span>E:</span>
                                                            info@anodesystems.com
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="footer-copyright-wrapper">
                        <div className="footer-copyright text-center">
                            COPYRIGHT © 2020. ALL RIGHTS RESERVED • MADE WITH{' '}
                            <FontAwesomeIcon
                                icon={faHeart}
                                style={{ color: 'red' }}
                            />{' '}
                            IN COLORADO • POWERED BY{' '}
                            <FontAwesomeIcon icon={faReact} /> &amp;{' '}
                            <FontAwesomeIcon icon={faNodeJs} />
                        </div>
                    </div>
                </div>
                <Button
                    variant="primary"
                    className="scroll-top"
                    id="scroll-top"
                    onClick={this.scrollToTop}
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </Button>
            </div>
        );
    }
}

export default Footer;
