import React, { Component } from 'react';

import NavBar from '../components/Navbar';
import MobileMenu from '../components/MobileMenu';

import USTHome from '../pages/Services/ust/USTHome'
import CorrosionBasics from '../pages/Services/education/CorrosionBasics'
import UnderstandingCathodicProtection from '../pages/Services/education/UnderstandingCathodicProtection'
import InterpretingVoltage from '../pages/Services/education/InterpretingVoltage'
import ElectricalGrounding from '../pages/Services/education/ElectricalGrounding'
import SteelPipe from '../pages/Services/education/SteelPipe'
import FiberglassLinedTanks from '../pages/Services/education/FiberglassLinedTanks'
import STIPTanks from '../pages/Services/education/STIPTanks'
import SteelSwingJoints from '../pages/Services/education/SteelSwingJoints'
import TimeSavingTips from '../pages/Services/education/TimeSavingTips'

import BrandLogoSlider from '../pages/HomePage/BrandLogoSlider';
import Footer from '../components/Footer';

/**
 * SIDEBARS
 */
import ServiceSidebar from '../components/Sidebar/ServiceSidebar';
import USTSidebar from '../components/Sidebar/USTSidebar';
import { Switch, Route } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';

class USTRoutes extends Component {
    render() {
        const { match } = this.props

        return (
            <div>
                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area ">
                    <Container>
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#0">Services</a></li>
                                        <li>Underground Storage Tanks</li>
                                    </ul>
                                </div>
                                </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--60">
                    {/*Service section start*/}
                    <div className="service-section">
                        <Container>
                            <Row>
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <div className="service-details">
                                        <div className="content section-space--top--30">
                                            <Row>
                                                <Col xs={12}>
                                                    <Switch>
                                                        <Route exact path={`${match.path}`} component={USTHome} />
                                                        <Route exact path={`${match.path}/corrosionbasics`} component={CorrosionBasics} />
                                                        <Route exact path={`${match.path}/understandingcathodicprotection`} component={UnderstandingCathodicProtection} />
                                                        <Route exact path={`${match.path}/interpretingvoltage`} component={InterpretingVoltage} />
                                                        <Route exact path={`${match.path}/electricalgrounding`} component={ElectricalGrounding} />
                                                        <Route exact path={`${match.path}/steelpipe`} component={SteelPipe} />
                                                        <Route exact path={`${match.path}/fiberglasslinedtanks`} component={FiberglassLinedTanks} />
                                                        <Route exact path={`${match.path}/stiptanks`} component={STIPTanks} />
                                                        <Route exact path={`${match.path}/steelswingjoints`} component={SteelSwingJoints} />
                                                        <Route exact path={`${match.path}/electricalgrounding`} component={ElectricalGrounding} />
                                                        <Route exact path={`${match.path}/TimeSavingTips`} component={TimeSavingTips} />
                                                    </Switch>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <ServiceSidebar />
                                    <br />
                                    <USTSidebar />
                                </div>
                            </Row>
                        </Container>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Brand logo */}
                <BrandLogoSlider background="grey-bg" />

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}


export default USTRoutes;