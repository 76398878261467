import React from 'react';
import { Row, Col } from 'react-bootstrap'

const IrrigationServiceGallery = () => {

    return (
        <div>
            <div className="service-gallery">
                <Row>
                    <Col xs={6}>
                        <img src="assets/img/services/irrigation/14_irr.jpg" className="img-fluid" alt=""></img>
                    </Col>
                    <Col xs={6}>
                        <img src="assets/img/services/irrigation/15_irr.jpg" className="img-fluid" alt=""></img>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <img src="assets/img/services/irrigation/13_irr.jpg" className="img-fluid" alt=""></img>
                    </Col>
                    <Col xs={6}>
                        <img src="assets/img/services/irrigation/12_irr.jpg" className="img-fluid" alt=""></img>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <img src="assets/img/services/irrigation/16_irr.jpg" className="img-fluid" alt=""></img>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default IrrigationServiceGallery;