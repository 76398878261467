import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

import { Link } from "react-router-dom";

const NewsBanner = () => {
    const mastHead = {
        background: "linear-gradient(#ffe65d,#ffcd03)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    };

    return (
        <header style={mastHead}>
            <Container className="h-100">
                <Row className="h-100 py-3">
                    <Col xs={{ span: 8, offset: 2 }}>
                        <Row>
                            <h1>News Update</h1>
                            <h3>
                                Open as early as 1928 the Tall Chief Theatre had
                                a spacious stage to accomodate vaudeville acts,
                                and was a large theatre for such a small town.
                                Designed in a commercial theme the structure had
                                limiited ornamentation, but still maintained a
                                stylish look.
                            </h3>
                            <Link to="/fairfaxtheatre">
                                <Button
                                    className="btn-lg mr-1"
                                    variant="outline-dark"
                                >
                                    <strong>Learn more</strong>
                                </Button>
                            </Link>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default NewsBanner;
