import React from 'react';
import { Container, Row } from 'react-bootstrap';

const AbovegroundStorageTank = () => {
    return (
        <div className="page-wrapper section-space--inner--60">
            {/*Projects section start*/}
            <div className="project-section">
                <Container>
                    <Row>
                        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
                            <div className="project-details">
                                <h2 className="py-5 text-center">Aboveground Storage Tanks</h2>
                                <h3>Potable Water Storage Tanks</h3>
                                <p>Steel water storage tanks will corrode and leak if left unprotected from the chlorinated water on the inside of the tank, and the earth on the outside (bottom) of the tank. Anode Systems Company has developed an innovative solution to installing internal corrosion protection anode systems. This unique feature of floating and floor mounted anodes protects the anode from damage sustained when ice forms on the top of the stored water in the winter. Older systems that hung anodes from the ceiling had to be removed in the fall or risk being pulled down by the ice in the winter. Anode Systems has engineered and installed floating systems in a 100,000-gallon system, 1-million-gallon system and 3-million-gallon system. (images below)</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <img className="w-100" src="/assets/img/services/potable/100k_5.jpg" alt=""></img>
                        </div>
                        <div className="col-12 col-lg-4">
                            <img className="w-100" src="/assets/img/services/potable/1m_3.jpg" alt=""></img>
                        </div>
                        <div className="col-12 col-lg-4">
                            <img className="w-100" src="/assets/img/services/potable/100k_3.jpg" alt=""></img>
                        </div>
                        <div className="col-12 col-lg-3">
                            <img className="w-100" src="/assets/img/services/potable/3m_1.jpg" alt=""></img>
                        </div>
                        <div className="col-12 col-lg-3">
                            <img className="w-100" src="/assets/img/services/potable/1m_2.jpg" alt=""></img>
                        </div>
                        <div className="col-12 col-lg-3">
                            <img className="w-100" src="/assets/img/services/potable/100k_1.jpg" alt=""></img>
                        </div>
                        <div className="col-12 col-lg-3">
                            <img className="w-100" src="/assets/img/services/potable/100k_2.jpg" alt=""></img>
                        </div>
                    </Row>
                </Container>
                <Container className="section-space--top--60">
                    <Row>
                        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
                            <div className="project-details">
                                <h3>Produced Water Tanks</h3>
                                <p>By-products of oil and natural gas production is par for the course, usually in the form of salt water, benzene, hydrocarbons or hazardous fluids. Salt water and elevated temperatures in particular increases the rate of internal corrosion of a steel tank, resulting in failure and ultimately replacement of the tank if left unprotected.</p>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <div className="col-12 col-lg-6">
                            <img className="w-100" src="/assets/img/services/installations/produced.jpg" alt=""></img>
                        </div>
                        <div className="col-12 col-lg-6">
                            <img className="w-100" src="/assets/img/services/installations/produced2.jpg" alt=""></img>
                        </div>
                    </Row>
                </Container>
                <Container className="section-space--top--60">
                    <Row>
                        <p>Cement pads alone do not protect against tank bottom corrosion. The ingress of blowing snow, rainwater, or the leaking of firewater sprinklers down the side-wall of the tank can bring in bacteria, chloride and sulfates that come to rest between the tank bottom and the foundation. If cement pads are installed on unstable soil, shifting of the earth can cause cracks in the concrete pad, allowing water and soil contaminates to intrude from the earth. In both instances, a corrosive environment can go undetected and cause tank bottom failure. Cathodic protection is not readily installed in concert with cement pad foundations due to concrete having a high electrical resistance, rendering CP systems ineffective.</p>
                    </Row>
                </Container>
                <Container className="section-space--top--60">
                    <Row>
                        <img className="w-100" src="/assets/img/services/installations/sandpads.jpg" alt=""></img>
                    </Row>
                </Container>
                <Container className="section-space--top--60">
                    <Row>
                        <p>Sand pads also fall victim to contamination by rain, snowmelt, and wicking action that can draw up soil pathogens such as chloride from the water table below. CP systems are placed in the sand between the tank bottom and a secondary polyethylene containment liner. This is common practice to protect tank bottoms against corrosion.</p>
                    </Row>
                </Container>
                <Container className="section-space--top--60">
                    <Row>
                        <img className="w-100" src="/assets/img/services/installations/bottomleak.jpg" alt=""></img>
                    </Row>
                </Container>
                <Container className="section-space--top--60">
                    <Row>
                        <p>Tank bottom leaks due to corrosion can be detrimental to the soil, a shallow water table, or nearby stream–negatively affecting the natural ecosystem and drinking water of nearby communities. Oil and gas companies are working to comply with the multiple laws and regulations that are in place to help protect the environment against costly spills and leaks. Anode Systems works alongside oil and gas companies to adopt responsible methods of storage and disposal of produced water, as regulated by the SPCC. Cathodic protection by Anode Systems can be placed either inside or outside the steel tanks, preventing corrosion of above ground steel storage tanks.</p>
                    </Row>
                </Container>
            </div>
        </div >
    )
}

export default AbovegroundStorageTank;