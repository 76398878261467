import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Topbar from '../Topbar';
import MobileMenu from '../MobileMenu';

import { Row, Container, Col } from 'react-bootstrap';

import { ReactComponent as Logo } from '../../../public/assets/img/logo/AnodeSystemsLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhone,
    faWarehouse,
    faEnvelopeSquare,
} from '@fortawesome/free-solid-svg-icons';
class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);
        this.mobileMenuElement = React.createRef();
    }

    activeMobileMenu = () => {
        this.mobileMenuElement.current.toggleMobileMenu();
    };

    handleScroll() {
        if (this.mount) {
            this.setState({ scroll: window.scrollY });
        }
    }

    componentDidMount() {
        this.mount = true;
        const el = document.querySelector('nav');
        this.setState({ top: el.offsetTop, height: el.offsetHeight });
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        this.state.scroll > this.state.top
            ? (document.body.style.paddingTop = `${this.state.height}px`)
            : (document.body.style.paddingTop = 0);
    }

    componentWillUnmount() {
        this.mount = false;
    }

    render() {
        return (
            <div>
                {/*====================  header area ====================*/}
                <div
                    className={`header-area header-sticky header-sticky--default ${
                        this.state.scroll > this.state.top ? 'is-sticky' : ''
                    }`}
                >
                    <div className="header-area__desktop header-area__desktop--default">
                        <Topbar />
                        {/*=======  header info area  =======*/}
                        <div className="header-info-area">
                            <Container>
                                <Row className="align-items-center">
                                    <Col lg={12}>
                                        <div className="header-info-wrapper align-items-center">
                                            {/* logo */}
                                            <div className="logo">
                                                <Link to={'/'}>
                                                    <Logo />
                                                </Link>
                                            </div>
                                            {/* header contact info */}
                                            <div className="header-contact-info">
                                                <div className="header-info-single-item">
                                                    <div className="header-info-single-item__icon">
                                                        <FontAwesomeIcon
                                                            icon={faPhone}
                                                            size={'lg'}
                                                        />
                                                    </div>
                                                    <div className="header-info-single-item__content">
                                                        <h6 className="header-info-single-item__title">
                                                            Phone
                                                        </h6>
                                                        <p className="header-info-single-item__subtitle">
                                                            1-888-609-9766
                                                            <br />
                                                            970-243-4149
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="header-info-single-item">
                                                    <div className="header-info-single-item__icon">
                                                        <FontAwesomeIcon
                                                            icon={faWarehouse}
                                                            size={'lg'}
                                                        />
                                                    </div>
                                                    <div className="header-info-single-item__content">
                                                        <h6 className="header-info-single-item__title">
                                                            Address
                                                        </h6>
                                                        <p className="header-info-single-item__subtitle">
                                                            124 N. 22nd Crt.
                                                            <br />
                                                            Grand Junction, CO
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="header-info-single-item">
                                                    <div className="header-info-single-item__icon">
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faEnvelopeSquare
                                                            }
                                                            size={'lg'}
                                                        />
                                                    </div>
                                                    <div className="header-info-single-item__content">
                                                        <h6 className="header-info-single-item__title">
                                                            Email
                                                        </h6>
                                                        <p className="header-info-single-item__subtitle">
                                                            info@anodesystems.com
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* mobile menu */}
                                            <div
                                                className="mobile-navigation-icon"
                                                id="mobile-menu-trigger"
                                                onClick={this.activeMobileMenu}
                                            >
                                                <i />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        {/*=======  End of header info area =======*/}
                        {/*=======  header navigation area  =======*/}
                        {/* <div className="header-navigation-area default-bg">
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <Navbar className="header-navigation header-navigation--header-default position-relative">
                                            <Nav>
                                                <NavDropdown title="SERVICES" id="ServiceDropdown">

                                                </NavDropdown>
                                                <NavDropdown title="EDUCATION" id="EducationDropdown">

                                                </NavDropdown>
                                                <NavDropdown title="INSTALLATION" id="InstallationDropdown">

                                                </NavDropdown>
                                                <Nav.Link>MATERIALS</Nav.Link>
                                                <Nav.Link>ARTIFACT CONSERVATION</Nav.Link>
                                                <NavDropdown title="CONTACT" id="ContactDropdown">

                                                </NavDropdown>
                                            </Nav>
                                        </Navbar>
                                    </Col>
                                </Row>
                            </Container>
                        </div> */}
                        <div className="header-navigation-area default-bg">
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        {/* header navigation */}
                                        <div className="header-navigation header-navigation--header-default position-relative">
                                            <div className="header-navigation__nav position-static">
                                                <nav>
                                                    <ul
                                                        style={{
                                                            marginBottom: '0px',
                                                        }}
                                                    >
                                                        <li className="has-children has-children--multilevel-submenu">
                                                            <Link
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                                to={'#'}
                                                                onClick={(e) =>
                                                                    e.preventDefault()
                                                                }
                                                            >
                                                                <strong>
                                                                    SERVICES
                                                                </strong>
                                                            </Link>
                                                            <ul className="submenu">
                                                                <li>
                                                                    <Link
                                                                        to={`/propane`}
                                                                    >
                                                                        PROPANE
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/oilgas`}
                                                                    >
                                                                        OIL
                                                                        &amp;
                                                                        GAS
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/potablewater`}
                                                                    >
                                                                        POTABLE
                                                                        WATER
                                                                        STORAGE
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/irrigation`}
                                                                    >
                                                                        IRRIGATION
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/ust`}
                                                                    >
                                                                        UST
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/mapping`}
                                                                    >
                                                                        GIS
                                                                        MAPPING
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="has-children has-children--multilevel-submenu">
                                                            <Link
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                                to={'#'}
                                                                onClick={(e) =>
                                                                    e.preventDefault()
                                                                }
                                                            >
                                                                {' '}
                                                                <strong>
                                                                    EDUCATION
                                                                </strong>
                                                            </Link>
                                                            <ul className="submenu">
                                                                <li>
                                                                    <Link
                                                                        to={`/education/anodes`}
                                                                    >
                                                                        ANODES
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/education/videos`}
                                                                    >
                                                                        VIDEOS
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/education/understandingcorrosion`}
                                                                    >
                                                                        UNDERSTANDING
                                                                        CORROSION
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="has-children has-children--multilevel-submenu">
                                                            <Link
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                                to={'#'}
                                                                onClick={(e) =>
                                                                    e.preventDefault()
                                                                }
                                                            >
                                                                {' '}
                                                                <strong>
                                                                    INSTALLATIONS
                                                                </strong>
                                                            </Link>
                                                            <ul className="submenu">
                                                                <li>
                                                                    <Link
                                                                        to={`/installations/pipeline`}
                                                                    >
                                                                        PIPELINES
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/installations/heatertreater`}
                                                                    >
                                                                        HEATER
                                                                        TREATER
                                                                        AND
                                                                        MARINE
                                                                        AQUEOUS
                                                                        APPLICATIONS
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/installations/ast`}
                                                                    >
                                                                        ABOVEGROUND
                                                                        STORAGE
                                                                        TANKS
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/installations/ust`}
                                                                    >
                                                                        UNDERGROUND
                                                                        STORAGE
                                                                        TANKS
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <a
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                                href="https://anodesalesco.com"
                                                            >
                                                                <strong>
                                                                    MATERIALS
                                                                </strong>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                                href="https://cedam.mx"
                                                            >
                                                                <strong>
                                                                    ARTIFACT
                                                                    CONSERVATION
                                                                </strong>
                                                            </a>
                                                        </li>
                                                        {/* <li><Link to={`${process.env.PUBLIC_URL}/news`}>NEWS</Link></li> */}
                                                        <li className="has-children has-children--multilevel-submenu">
                                                            <Link
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                                to="#"
                                                                onClick={(e) =>
                                                                    e.preventDefault()
                                                                }
                                                            >
                                                                {' '}
                                                                <strong>
                                                                    CONTACT
                                                                </strong>
                                                            </Link>
                                                            <ul className="submenu">
                                                                <li>
                                                                    <Link
                                                                        to={`/contactus`}
                                                                    >
                                                                        CONTACT
                                                                        US
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={`/meettheteam`}
                                                                    >
                                                                        MEET THE
                                                                        TEAM
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <a
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                                href="/press-release"
                                                            >
                                                                <strong>
                                                                    NEWS
                                                                </strong>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        {/*=======  End of header navigation area =======*/}
                    </div>
                </div>
                {/*====================  End of header area  ====================*/}

                {/* Mobile Menu */}
                <MobileMenu ref={this.mobileMenuElement} />
            </div>
        );
    }
}

export default NavBar;
