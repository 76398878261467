import React from 'react';
import { withRouter } from 'react-router-dom'
import { Button, Row, Container, Col } from 'react-bootstrap';

const AboutText = ({ history }) => {

    return (
        <div>
            {/*====================  about text area ====================*/}
            <div className="about-text-area grey-bg section-space--inner--30">
                <Container>

                    <Row className="align-items-center">
                        <Col lg={12}>
                            <div className="section-title-area text-center section-space--bottom--20">
                                <h2 style={{ color: '#000' }} className="section-title">About Us</h2>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className="video-cta-content">
                                <h3 className="video-cta-content__title">Anode Systems Company is an industry leader in the field of cathodic protection and corrosion prevention, maintaining superiority since 1984.</h3>
                                <p className="video-cta-content__text">Our team of experienced NACE certified cathodic protection specialist and field technicians survey, design, install, test and maintain cathodic protection systems throughout the United States.</p>
                                <Button
                                    style={{color:'#000'}}
                                    variant="primary"
                                    onClick={() => { history.push('/contactus') }}
                                    className="ht-btn ht-btn--round btn-block"
                                > <strong>CONTACT US</strong>
                                </Button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="cta-video-image__image">
                                <img src="assets/img/bg/about.png" style={{ height: "100%" }} className="" alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*====================  End of about text area  ====================*/}
        </div>
    )
}


export default withRouter(AboutText);