import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServiceSidebar extends Component {
    render() {
        let data = [
            {
                sidebarListLink: '/propane',
                sidebarListIcon: 'fas fa-burn',
                sidebarListText: 'Propane',
            },
            {
                sidebarListLink: '/oilgas',
                sidebarListIcon: 'flaticon-010-tank-1',
                sidebarListText: 'Oil & Gas',
            },
            {
                sidebarListLink: '/potablewater',
                sidebarListIcon: 'flaticon-002-welding',
                sidebarListText: 'Potable Water Storage',
            },
            {
                sidebarListLink: '/irrigation',
                sidebarListIcon: 'flaticon-004-walkie-talkie',
                sidebarListText: 'Irrigation',
            },
            {
                sidebarListLink: '/ust',
                sidebarListIcon: 'flaticon-042-monitor',
                sidebarListText: 'UST',
            },
        ];

        let Datalist = data.map((val, i) => {
            return (
                <li className="" key={i}>
                    <Link to={val.sidebarListLink}>
                        <i className={val.sidebarListIcon} />
                        {val.sidebarListText}
                    </Link>
                </li>
            );
        });

        return (
            <div>
                <div className="sidebar-wrapper">
                    <div className="sidebar">
                        <h3 className="sidebar-title">Services</h3>
                        <ul className="sidebar-list">{Datalist}</ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceSidebar;
