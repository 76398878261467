import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'

const UndergroundStorageTanks = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Underground Storage Tanks</h2>
                <Container className="section-space--top--60">
                    <Row>
                        <Col xs={4} className="project-image py-3">
                            <img className="w-100 image-fluid" src="/assets/img/services/installations/ust.jpg" alt="" />
                        </Col>
                        <Col xs={8}>
                            <p>Underground propane tanks are the preferred choice when installing large residential tanks due to their unsightly size, inability to blend into the landscaping, and consumption of vital usable real estate. Underground installation also helps protect against fire and extreme weather temperatures, as fluctuating temperatures can lead to unnecessary propane gas evaporation. Underground storage allows the tank to maintain a steady temperature, much like a wine cellar. Massive sized commercial propane tanks (30,000 gallon+) also benefit from the above list of reasons, as any real estate developer would suggest.</p>
                        </Col>
                    </Row>
                </Container>
                <Container className="section-space--top--60">
                    <Row>
                        <Col xs={8}>
                            <p>The LP-Gas Code deemed cathodic protection a requirement on all newly installed buried propane tanks after January 1, 2011, however it is not a retroactive requirement on older tank systems. Anode Systems Company specializes in providing cathodic protection to underground propane tanks across the United States since 1994. Tank coatings provide the initial barrier against soil debris and contaminates but no coating is perfect. Pitting of the coating occurs when ‘pathogens’ in the soil attack– rocks, salts, fertilizer, moisture, natural minerals that conduct electricity, chemicals in septic tank drain fields, and so on. Anodes work in concert with tank coating and should always be used in conjunction with one another to provide ultimate protection.</p>
                        </Col>
                        <Col xs={4} className="project-image py-3">
                            <img className="w-100 imageFluid" src="/assets/img/services/installations/ust2.jpg" alt="" />
                        </Col>
                    </Row>
                </Container>
                <p>Talk to your propane provider today and make sure your underground tanks are protected with premium quality alloy anodes from Anode Systems Company!</p>
                <Container className="section-space--top--60">
                    <Row>
                        <Col xs={8} className="section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
                            <div className="project-details">
                                <h3>Petroleum/Liquid Fuel Storage Tanks</h3>
                                <p>Steel Underground Storage Tanks, or UST’s, remain popular with fuel stations because of their proven long-term strength, impermeability, and are cost effective. Underground tanks continue to be the most accepted and favorable option for storage, as real estate is prime and fire protection is vital. Even with newly installed double walled coated steel tanks, cathodic protection is a simple and economical solution to combating corrosion.</p>
                            </div>
                        </Col>
                        <Col xs={4} className="project-image py-3">
                            <img src="/assets/img/services/installations/gasstation.jpg" className="img-fluid w-100" alt="" />
                        </Col>

                    </Row>
                </Container>
        </div>
        </div >
    )
}

export default UndergroundStorageTanks;