import React from 'react';
import { Row, Col } from 'react-bootstrap'

const SteelPipe = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Steel Pipe and Bare or Coated Tanks</h2>
                <div className="col-10 offset-1 project-image py-3"><img src="/assets/img/services/ust/UST-4.jpg" className="img-fluid" alt="" /></div>
                <p>Bare tanks and pipes at an average site containing three tanks will require about 4 amps or less for protection. Three coated tanks however, require 2 amps or less. The current output needed is affected by the amount of bare pipe, bare conduit and structural steel at the site. If one is unsure whether or not a site has STIP or bare steel tanks, the electrical resistance between tanks can provide the answer. A low resistance indicates the tanks are bare and not STIP. A high resistance over 100 ohms would indicate STIP tanks.</p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/corrosionbasics">01</a></li>
                        <li><a href="/ust/understandingcathodicprotection">02</a></li>
                        <li><a href="/ust/interpretingvoltage">03</a></li>
                        <li><a href="/ust/electricalgrounding">04</a></li>
                        <li className="active"><a href="/ust/steelpipe">05</a></li>
                        <li><a href="/ust/fiberglasslinedtanks">06</a></li>
                        <li><a href="/ust/stiptanks">07</a></li>
                        <li><a href="/ust/steelswingjoints">08</a></li>
                        <li><a href="/ust/timesavingtips">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/electricalgrounding"><i className="fa fa-angle-left" /> Prev</a></li>
                        <li><a href="/ust/fiberglasslinedtanks"><i className="fa fa-angle-right" /> Next</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}


export default SteelPipe;