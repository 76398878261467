import React from 'react';
import { Row, Col } from 'react-bootstrap'

const WhatIsCorrosion = () => {
    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2 order-lg-2">
            <div className="project-details">
                <h2 className="py-5 text-center">What Is Corrosion?</h2>
                <Row>
                    <div className="col-12 col-md-6">
                        <div className="project-image pb-3 text-center"><img src="/assets/img/services/education/whatiscorrosion.jpg" className="img-fluid" alt="" /></div>
                    </div>
                    <div className="col-12 col-md-6">
                        <p>Corrosion of a steel tank or copper pipe is an electrical and chemical deterioration of the metal. It is sometimes called "electrolysis." It causes pits which leads to holes in one place but not in another. The electrical component of corrosion involves the movement of electrons across a metal surface like electricity flowing on a copper wire. Because of this, the corrosion rate can be measured in milliamps and is affected by resistance and voltage differences.</p>
                    </div>
                    <Col>
                        <p>Electricity flows from a high voltage potential to a lower voltage potential much like water flows from a high elevation to a lower elevation. Electricity, like water, is affected by resistance and pressure. All metals have energy stored within them relative to the amount of energy it took to create them from ore. Magnesium has the most energy stored within it. Zinc and aluminum have slightly less energy followed by steel then cast iron. lead, brass and copper have lower energy which causes them to be only mildly affected by the corrosion process. Carbon, a metal, and gold have the lowest energy levels of all the above metals. They all have a unique voltage that can be measured.</p>
                        <p>If two different metals are placed in a substance such as water or soil that conducts electricity and connected together, you can measure an electric current that will flow from the metal with the most stored energy to the metal with the least stored energy. This is how batteries work. The illustration at right illustrates corrosion in a battery. The chemical component of corrosion involves the combination of water ions, oxygen and other negatively charged ions with positively charged iron to form iron oxide which we call rust. Dissolved salt and other minerals in water accelerate the rate of corrosion.</p>
                        <p>When a tank corrodes, the iron atoms break away from the steel surface. Over time, this leaves pits in the steel which eventually become holes. Corrosion occurs at an anode. Therefore, pits occur at anodic spots on the surface. Protection occurs at a cathode. Therefore, the unpitted areas are cathodic spots.</p>
                    </Col>
                </Row>

            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li className="active"><a href="/propane/whatiscorrosion">01</a></li>
                        <li><a href="/propane/whatmakessoilcorrosive">02</a></li>
                        <li><a href="/propane/whatiscathodicprotection">03</a></li>
                        <li><a href="/propane/allaboutmagnesiumanodes">04</a></li>
                        <li><a href="/propane/awordaboutcoatings">05</a></li>
                        <li><a href="/propane/whatiselectricalisolation">06</a></li>
                        <li><a href="/propane/howtoinstallananode">07</a></li>
                        <li><a href="/propane/testingforcorrosionprotection">08</a></li>
                        <li><a href="/propane/references">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/propane"><i className="fa fa-angle-left" /> Prev</a></li>
                        <li><a href="/propane/whatmakessoilcorrosive"><i className="fa fa-angle-right" /> Next</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}


export default WhatIsCorrosion;