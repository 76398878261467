import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import NavBar from "../../components/Navbar";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu";
import MyForm from "./MyForm";
import Breadcrumbs from "../../components/Breadcrumbs";

const Contact = () => {
    const myPath = ["/", "/contactus"];
    return (
        <div>
            {/* Navigation bar */}
            <NavBar />

            {/* breadcrumb */}
            {/*====================  breadcrumb area ====================*/}

            <Breadcrumbs path={myPath} />

            <div className="breadcrumb-area ">
                <Container>
                    <Row>
                        <Col>
                            <div className="page-banner text-center">
                                <ul className="page-breadcrumb">
                                    <li>
                                        <a style={{ color: "white" }} href="/">
                                            Home
                                        </a>
                                    </li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*====================  End of breadcrumb area  ====================*/}

            {/*====================  content page content ====================*/}
            <div className="page-wrapper section-space--inner--60">
                {/*Contact section start*/}
                <div className="contact-section">
                    <Container>
                        <Row className="section-space--bottom--50">
                            <Col>
                                <div className="contact-map">
                                    <iframe
                                        title="google-map"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3097.6545004238983!2d-108.54213258407134!3d39.06878714442741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87471c16964ff6e1%3A0x35e8e3ce83959272!2sAnode+Systems+Company!5e0!3m2!1sen!2sus!4v1558031385672!5m2!1sen!2sus"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={4}>
                                <div className="contact-information">
                                    <h3>Contact Information</h3>
                                    <ul>
                                        <li>
                                            <span className="icon">
                                                <i className="ion-android-map" />
                                            </span>
                                            <span className="text">
                                                <span>
                                                    124 N 22nd Court, <br />
                                                    Grand Junction, CO USA
                                                </span>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon">
                                                <i className="ion-ios-telephone-outline" />
                                            </span>
                                            <span className="text">
                                                <a href="tel:19702434149">
                                                    (970) 243-4149
                                                </a>
                                                <a href="tel:18886099766">
                                                    (888) 609-9766
                                                </a>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon">
                                                <i className="ion-ios-email-outline" />
                                            </span>
                                            <span className="text">
                                                <a href="mailto:info@anodesystems.com">
                                                    info<code>@</code>
                                                    anodesystems.com
                                                </a>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            <Col xs={12} lg={8}>
                                <div className="contact-form">
                                    <h3>Leave Your Message</h3>
                                    <MyForm />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*Contact section end*/}
            </div>
            {/*====================  End of content page content  ====================*/}

            {/* Footer */}
            <Footer />

            {/* Mobile Menu */}
            <MobileMenu />
        </div>
    );
};

export default Contact;
