import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

class FeatureIconText extends Component {
    render() {

        let data = [
            { featureIcon: "survey.png", featureTitle: "Surveys", featureDescription: "Anode Systems, Engineer and Technicians are recognized for their experience in Cathodic Protection." },
            { featureIcon: "design.png", featureTitle: "Design", featureDescription: "CP Design starts with excellent customer service and a thorough understanding of the structure to be protected." },
            { featureIcon: "installation.png", featureTitle: "Installations", featureDescription: "With Installations across the US, Our vast knowledge of CP Installation techniques allows us to meet your projects needs." },
            { featureIcon: "maintenance.png", featureTitle: "Maintenance", featureDescription: "Testing everything from surface bed and deep anode bed impressed current and galvanic anode systems on pipelines to tanks and steel structures." }
        ];

        let Datalist = data.map((val, i) => {
            return (
                <Col lg={3} md={6} key={i}>
                    <div className="single-feature-icon">
                        <div className="single-feature-icon__image">
                            <img src={`assets/img/icons/${val.featureIcon}`} className="img-fluid" alt="" />
                        </div>
                        <h3 className="single-feature-icon__title">{val.featureTitle}</h3>
                        <p className="single-feature-icon__content">{val.featureDescription}</p>
                    </div>
                </Col>
            )
        });

        return (
            <div>
                {/*====================  feature icon area ====================*/}
                <div className="feature-icon-area section-space--inner--30 white-bg">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="feature-icon-wrapper">
                                    <Row>
                                        {Datalist}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of feature icon area  ====================*/}
            </div>
        )
    }
}

export default FeatureIconText;
