import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap'

import NavBar from '../components/Navbar';
import MobileMenu from '../components/MobileMenu';

import ServiceSidebar from '../components/Sidebar/ServiceSidebar';

import BrandLogoSlider from '../pages/HomePage/BrandLogoSlider';
import Footer from '../components/Footer';



class MappingRoutes extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <div className="breadcrumb-area">
                    <Container>
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#0">Services</a></li>
                                        <li>Flowline Compliance</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--60">
                    {/*Service section start*/}
                    <div className="service-section">
                        <Container>
                            <Row>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <ServiceSidebar />
                                </div>
                                <div className=" col-lg-8 col-12 order-1 order-lg-2">
                                    <div className="service-details">
                                        <div className="content section-space--top--30">
                                            <Row>
                                                <Col xs={12}>
                                                    <h2>Flowline Compliance</h2>
                                                    <p>Recently, The Colorado Oil & Gas Conservation Commission (COGCC) recently approved the new regulations regarding the construction, installation, tracking, testing and abandoning of flowlines in Colorado.</p>
                                                    <p>The changes arrived a year after the Home explosion in Firestone, CO that killed two people and injured another. The Firestone Fire Protection District officials believe that an abandoned gas line need the home was cut, causing an odor gas to leak into the home from the basement. “We believe these new rules are another important step in the aftermath of the Firestone tragedy, Gov. John Hickenlooper said in a statement”</p>
                                                    <p>Here is a look at some of the changes and how Anode Systems can help you stay in compliance.</p>
                                                    <ol>
                                                        <li>
                                                            Operators must register their flowlines with the COGCC. The COGCC wants to know what the line is made of, what it’s carrying, where it’s coming from and where it ends, requiring a geodatabase containing pipeline alignment if the pipeline is construct on or after May 1, 2018. <br /><br /><strong>Anode Systems Co. can help with submitting Form 44, the registration for off-location flowlines and obtaining GPS endpoints.</strong>
                                                        </li>
                                                        <br />
                                                        <li>
                                                            Operators must now report domestic gas taps via Form 44 including the GPS location of the point the tap connects to a flowline or wellhead and the address of where it delivers gas.<br /><br /> <strong>Anode Systems can help with submitting Form 44 and obtaining GPS points.</strong>
                                                        </li>
                                                    </ol>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Brand logo */}
                <BrandLogoSlider background="grey-bg" />

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div >
        )
    }
}


export default MappingRoutes;