import React from 'react';
import { Row, Col } from 'react-bootstrap'

const TestingForCorrosionProtection = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Testing For Corrosion Protection</h2>
                <p>So, how do you know if your tank is protected? It only takes a minute to check. As stated in the section on cathodic protection, if the voltage of steel is -0.85 volts or greater, it is protected. It takes a voltmeter and a copper sulfate electrode to take the reading. It can be taken by a propane gas supplier technician with a basic understanding of cathodic protection. If the reading is greater than -0.85 volts, you can assume that the tank is protected at the location where the reading was taken on the date it was taken. If the reading is below -0.85 volts, the anode installed on the tank either has been consumed, or the tank is connected to the house utilities without a dielectric union or the wire has been disconnected. Occasionally, there is a problem with the testing equipment or contact to the tank. It takes practice and experience to find the answer to these problems. There are other causes for a low reading which might require a cathodic protection technician or a corrosion engineer to determine.</p>
                <p>Contact to the tank can be made on any metal connected to the tank. This includes the gauging device, the regulator, the pressure relief valve or the fittings. The dome would not be a good contact point as it does not make good metal contact with the tank.</p>
                <h3 className="text-center">As A Homeowner, I would like to see:</h3>
                <p>Some tank installers are able to route the anode wire into the dome or even attach a separate test lead wire from the tank and run it into the dome. Contact can be made on either of these wires. I would recommend taking at least four readings around the tank.</p>
                <p>A copy of the readings with a written report showing when and how the readings were taken. A drawing to accompany the report. The qualifications of the person who tested my tank. This done once a year.</p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/propane/whatiscorrosion">01</a></li>
                        <li><a href="/propane/whatmakessoilcorrosive">02</a></li>
                        <li><a href="/propane/whatiscathodicprotection">03</a></li>
                        <li><a href="/propane/allaboutmagnesiumanodes">04</a></li>
                        <li><a href="/propane/awordaboutcoatings">05</a></li>
                        <li><a href="/propane/whatiselectricalisolation">06</a></li>
                        <li><a href="/propane/howtoinstallananode">07</a></li>
                        <li className="active"><a href="/propane/testingforcorrosionprotection">08</a></li>
                        <li><a href="/propane/references">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/propane/howtoinstallananode"><i className="fa fa-angle-left" /> Prev</a></li>
                        <li><a href="/propane/references"><i className="fa fa-angle-right" /> Next</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default TestingForCorrosionProtection;