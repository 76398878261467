import React, { Component } from "react";
import { Row, Container, Col } from "react-bootstrap";

class FeatureIcon extends Component {
    render() {
        let data = [
            {
                featureIcon: "feature-1.png",
                featureTitle: "Top Rated",
                featureDescription:
                    "We have been in the business since 1984, and we have remained an industry leader",
            },
            {
                featureIcon: "feature-2.png",
                featureTitle: "Best Quality",
                featureDescription:
                    "Our Anodes are hand-made and their quality speaks for themselves",
            },
            {
                featureIcon: "feature-3.png",
                featureTitle: "Low Cost",
                featureDescription:
                    "Give us a call today, see how we can help you stay in compliance at a reasonable cost.",
            },
        ];

        let Datalist = data.map((val, i) => {
            return (
                <Col
                    xs={12}
                    md={6}
                    lg={4}
                    className="section-space--bottom--30"
                    key={i}
                >
                    <div className="feature">
                        <div className="icon">
                            <img
                                className="img-fluid"
                                src={`assets/img/icons/${val.featureIcon}`}
                                alt=""
                            />
                        </div>
                        <div className="content">
                            <h3>{val.featureTitle}</h3>
                            <p>{val.featureDescription}</p>
                        </div>
                    </div>
                </Col>
            );
        });

        return (
            <div>
                {/*Feature section start*/}
                <div
                    className={`feature-section section-space--inner--100 ${this.props.background}`}
                >
                    <Container>
                        <Col lg={12}>
                            <div className="feature-item-wrapper">
                                <Row>{Datalist}</Row>
                            </div>
                        </Col>
                    </Container>
                </div>
                {/*Feature section end*/}
            </div>
        );
    }
}

export default FeatureIcon;
