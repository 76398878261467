import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class TeamMemberGrid extends Component {
    render() {
        /* team member data */

        let data = [
            {
                profileImage: "Hans.png",
                profileTitle: "Hans Schmoldt",
                profileDesignation: "NACE CP Specialist #4162",
            },
            {
                profileImage: "Junior.jpg",
                profileTitle: "Reynaldo Fuentes",
                profileDesignation: "Lead Field Technician",
            },
            {
                profileImage: "Jaime.jpg",
                profileTitle: "Jaime Herrera",
                profileDesignation: "Field Technician",
            },
        ];

        let Datalist = data.map((val, i) => {
            return (
                <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="section-space--bottom--30"
                    key={i}
                >
                    <div className="team">
                        <div className="image">
                            <img
                                src={`/assets/img/team/${val.profileImage}`}
                                alt=""
                            />
                        </div>
                        <div className="content">
                            <h3 className="title">{val.profileTitle}</h3>
                            <span>{val.profileDesignation}</span>
                            <a
                                href={"mailto:" + val.profileEmail}
                                className="email"
                            >
                                {val.profileEmail}
                            </a>
                        </div>
                    </div>
                </Col>
            );
        });
        return (
            <div className="team-member-area section-space--inner--60 pt-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="section-title-area text-center">
                                <h2 className="section-title section-space--bottom--50">
                                    {" "}
                                    Our Team
                                </h2>
                                <hr />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="team-member-wrapper">
                                <Row>{Datalist}</Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default TeamMemberGrid;
