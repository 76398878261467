import React from 'react'

const EducationHome = () => {
    return (
        <React.Fragment>
            <h2>Education and Instructionals</h2>
            <p>Cathodic protection is used all over the world to protect a wide range of metallic structures across a variety of industries in a wide range of environments, including residential and commercial settings.</p>
            <p>The following was written to answer the questions I have been asked about anodes, cathodic protection, corrosion control, installation practices and the harmful corrosive effect soil has on underground propane tanks. This information is for homeowners, tank manufacturers, suppliers, installers, maintenance men and architects. It explains why cathodic protection from anodes is needed to protect underground tanks from corrosion. This is a brief overview of this subject.</p>
        </React.Fragment>
    )
}

export default EducationHome