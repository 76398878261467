import React from 'react';

const LeaksCanBeCostly = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <h3 className="text-center py-5">Leaks Can Be Costly</h3>
            <div className="project-details">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Size of Leak</th>
                            <th scope="col">Gallons Per Hour</th>
                            <th scope="col">Gallons Per Day</th>
                            <th scope="col">Gallons Per Month</th>
                            <th scope="col">Gallons Per Year</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1/2 Drop Per Second</td>
                            <td>0.05</td>
                            <td>1.2</td>
                            <td>36</td>
                            <td>438</td>
                        </tr>
                        <tr>
                            <td>Intermittent Stream</td>
                            <td>0.9</td>
                            <td>21.6</td>
                            <td>64.8</td>
                            <td>7776</td>
                        </tr>
                        <tr>
                            <td>1/16" Stream</td>
                            <td>3</td>
                            <td>72</td>
                            <td>2160</td>
                            <td>25920</td>
                        </tr>
                        <tr>
                            <td>1/8" Stream</td>
                            <td>10.2</td>
                            <td>244.8</td>
                            <td>7344</td>
                            <td>88128</td>
                        </tr>
                        <tr>
                            <td>3/16" Stream</td>
                            <td>14</td>
                            <td>336.9</td>
                            <td>10107</td>
                            <td>121284</td>
                        </tr>
                        <tr>
                            <td>1/4" Stream</td>
                            <td>38.8</td>
                            <td>931.2</td>
                            <td>27396</td>
                            <td>335232</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default LeaksCanBeCostly;