import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';

import Home from './pages/HomePage/index';
import Layout from './Routes'

import './index.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}/`}
                        render={(props) => (
                            <Layout {...props} homeComponent={Home}/>
                        )}
                    />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));