/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'

import NavBar from '../components/Navbar';
import MobileMenu from '../components/MobileMenu';

import PropanePageHome from '../pages/Services/propane/PropaneHome'
import WhatIsCorrosion from '../pages/Services/education/WhatIsCorrosion'
import WhatMakesSoilCorrosive from '../pages/Services/education/WhatMakesSoilCorrosive'
import WhatIsCathodicProtection from '../pages/Services/education/WhatIsCathodicProtection'
import AllAboutMagnesiumAnodes from '../pages/Services/education/AllAboutMagnesiumAnodes'
import AWordAboutCoatings from '../pages/Services/education/AWordAboutCoatings'
import ElectricalIsolation from '../pages/Services/education/WhatIsElectricalIsolation'
import HowToInstallAnAnode from '../pages/Services/education/HowToInstallAnAnode'
import TestingForCorrosionProtection from '../pages/Services/education/TestingForCorrosionProtection'
import References from '../pages/Services/education/References'
import PropaneSidebar from '../components/Sidebar/PropaneSidebar'
import ServiceSidebar from '../components/Sidebar/ServiceSidebar';

import BrandLogoSlider from '../pages/HomePage/BrandLogoSlider';
import Footer from '../components/Footer';

class PropaneServiceDetails extends Component {


    state = { numPages: null };

    onDocumentLoadSuccess = (document) => {
        const { numPages } = document
        this.setState({ numPages })
    }

    render() {
        const { match } = this.props

        return (
            <div>
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area">
                    <Container>
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/" style={{ color: 'white' }}>Home</a></li>
                                        <li><a href="#" style={{ color: 'white' }}>Services</a></li>
                                        <li>Propane</li>
                                    </ul>
                                </div>
                            </Col>

                        </Row>

                    </Container>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--60">
                    {/* <Jumbotron>
                        <div className="display-3">Propane</div>
                    </Jumbotron> */}
                    {/*Service section start*/}
                    <div className="service-section">
                        <Container>
                            <Row>

                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <div className="service-details">
                                        <div className="content section-space--top--30">
                                            <Row>
                                                <Col xs={12}>
                                                    <Switch>
                                                        <Route exact path={`${match.path}`} component={PropanePageHome} />
                                                        <Route exact path={`${match.path}/whatiscorrosion`} component={WhatIsCorrosion} />
                                                        <Route exact path={`${match.path}/whatmakessoilcorrosive`} component={WhatMakesSoilCorrosive} />
                                                        <Route exact path={`${match.path}/whatiscathodicprotection`} component={WhatIsCathodicProtection} />
                                                        <Route exact path={`${match.path}/allaboutmagnesiumanodes`} component={AllAboutMagnesiumAnodes} />
                                                        <Route exact path={`${match.path}/awordaboutcoatings`} component={AWordAboutCoatings} />
                                                        <Route exact path={`${match.path}/whatiselectricalisolation`} component={ElectricalIsolation} />
                                                        <Route exact path={`${match.path}/howtoinstallananode`} component={HowToInstallAnAnode} />
                                                        <Route exact path={`${match.path}/testingforcorrosionprotection`} component={TestingForCorrosionProtection} />
                                                        <Route exact path={`${match.path}/references`} component={References} />
                                                    </Switch>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <ServiceSidebar />
                                    <br />
                                    <PropaneSidebar />
                                </div>
                            </Row>
                        </Container>
                    </div>
                </div>
                {/*Service section end*/}


                {/* Brand logo */}
                <BrandLogoSlider background="grey-bg" />

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div >
        )
    }
}


export default PropaneServiceDetails;