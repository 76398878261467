import React from 'react';
import { Row, Col } from 'react-bootstrap'

const ElectricalGrounding = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Electrical Grounding</h2>
                <p>
                    Most of the problems of unprotected UST or AST installations that were retrofitted with magnesium anodes can be traced back to
                    electrical grounding. By code, the ground wires and conduit that run to the submersible pumps and dispensers must be grounded
                    at the electric breaker box to the water pipes in the building. This ties the underground copper and cast iron water piping on
                    the property to the tanks or piping. It also connects the structural steel in the building or at the canopy to the tanks or piping.
                    The anodes are not able to protect all the underground metal connected to the electric grounds.
                    </p>
                <div className="col-10 offset-1 project-image py-3"><img src="/assets/img/services/ust/UST-3.jpg" className="img-fluid" alt="" /></div>
                <p>
                    This is why a steel pipe, flex connector, swing joint or tank is unprotected even though magnesium anodes were installed. It is
                    usually much more time consuming and costly to try to isolate the underground piping from the electric lines than it is to cathodically
                    protect everything with an impressed current system. At AST sites, copper ground rods are normally attached to the tanks for lightning
                    or static electricity protection. When a magnesium anode is installed, the protective current is attracted to the copper ground rod
                    instead of the steel tank bottom. This contractor could not protect even a Single 10' diameter tank with magnesium anodes because of
                    electrical grounding. An impressed current system can protect several 10' diameter tank bottoms, the piping that is in contact with
                    the earth and buried electric utilities with as little as one anode.
                    </p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/corrosionbasics">01</a></li>
                        <li><a href="/ust/understandingcathodicprotection">02</a></li>
                        <li><a href="/ust/interpretingvoltage">03</a></li>
                        <li className="active"><a href="/ust/electricalgrounding">04</a></li>
                        <li><a href="/ust/steelpipe">05</a></li>
                        <li><a href="/ust/fiberglasslinedtanks">06</a></li>
                        <li><a href="/ust/stiptanks">07</a></li>
                        <li><a href="/ust/steelswingjoints">08</a></li>
                        <li><a href="/ust/timesavingtips">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/interpretingvoltage"><i className="fa fa-angle-left" /> Prev</a></li>
                        <li><a href="/ust/steelpipe"><i className="fa fa-angle-right" /> Next</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}


export default ElectricalGrounding;