import React from 'react'

import NavBar from '../../components/Navbar';

var __html = require('../../data/2020ChipetaSolarSpringsSeminar')
var template = { __html: __html };

const ChipetaWebinar = (params) => {
    return (
        <div>
            <NavBar/>
            <span dangerouslySetInnerHTML={template}></span>
        </div >
    )
}

export default ChipetaWebinar
