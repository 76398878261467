import React, { useState } from 'react';
import PropaneServiceGallery from '../propane/PropaneServiceGallery';
import { PDFReader } from 'reactjs-pdf-reader';
import { Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faChevronCircleLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import Youtube from 'react-youtube-embed'

const PropaneHome = () => {
    const [pageNumber, setPageNumber] = useState(1)

    return (

        <React.Fragment>

            <PropaneServiceGallery />
            <Col xs={12}>
                <h2 className="text-center py-4">Propane</h2>
                <p>Cathodic protection is used all over the world to protect a wide range of metallic structures across a variety of industries in a wide range of environments, including residential and commercial settings.</p>
                <p>The following was written to answer the questions I have been asked about anodes, cathodic protection, corrosion control, installation practices and the harmful corrosive effect soil has on underground propane tanks. This information is for homeowners, tank manufacturers, suppliers, installers, maintenance men and architects. It explains why cathodic protection from anodes is needed to protect underground tanks from corrosion. This is a brief overview of this subject.</p>
                <br />
                <div>
                    <PDFReader width={'600'} url={process.env.PUBLIC_URL + "/assets/pdf/Cathodic-Protection-Manual.pdf"} page={pageNumber} />
                </div>
                <Row className="mt-4 paginationContainer">
                    <button className="mr-1" onClick={() => (setPageNumber(pageNumber - 1))}>
                        <FontAwesomeIcon icon={faChevronCircleLeft} size={'lg'} />
                    </button>
                    <button className="mr-1" onClick={() => (setPageNumber(0))}>
                        <FontAwesomeIcon icon={faHome} size={'lg'} />
                    </button>
                    <button className="ml-1" onClick={() => (setPageNumber(pageNumber + 1))}>
                        <FontAwesomeIcon icon={faChevronCircleRight} size={'lg'} />
                    </button>
                </Row>
                <Row className="mt-4 paginationContainer">
                    <Button href="/assets/pdf/Cathodic-Protection-Manual.pdf" >
                        Click here to download PDF Version
                    </Button>

                </Row>
            </Col>
            <br />
            <Col xs={12}>
                <Row>
                    <h2 className="text-center">Also, Please find the PERC Manual DVD in its entirety below</h2>
                    <Youtube id='-sUrNHlBVqQ' />
                </Row>
            </Col>
        </React.Fragment>
    )
}
export default PropaneHome