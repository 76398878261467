import React from 'react';

const Projects = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">EPA Announces Final Amendments to the Oil Spill Prevention, Control, and Countermeasure (SPCC) Rule</h2>
                <p>On November 5, 2009, the EPA Administrator signed a notice amending certain requirements of the Spill Prevention, Control, and Countermeasure (SPCC) rule in order to address additional areas of regulatory reform that have been raised by the regulated community. This action promulgates revisions to the December 2008 amendments as a result of EPA's review of comments and consideration of all relevant facts. EPA is either taking no action or providing minor technical corrections on the majority of the December 2008 provisions. However, this action modifies the December 2008 rule by removing the provisions to: exclude farms and oil production facilities from the loading/unloading rack requirements; exempt produced water containers at an oil production facility; and provide alternative qualified facilities eligibility criteria for an oil production facility.</p>
                <p>Additionally, because of the uncertainty surrounding the final amendments to the December 5, 2008, rule and the delay of the effective date, EPA will propose to extend the compliance date. This rule is effective January 14, 2010.</p>
                <br />
                <h3>Regulation: Oil Pollution Prevention; Spill Prevention, Control, and Countermeasure Rule - Final Amendments</h3>
                <p>On December 5, 2008, the Federal Register published EPA's amendments to the SPCC rule in order to provide increased clarity, to tailor requirements to particular industry sectors, and to streamline certain requirements for those facility owners or operators subject to the rule, which should result in greater protection to human health and the environment.</p>
                <p>On April 1, 2009, the Federal Register published EPA's delay of the effective date of the December 5, 2008, Oil Spill Prevention, Control, and Countermeasure final rule. This delay is in response to public comments and the</p>
                <p>The December 5, 2008, amendments will become effective on January 14, 2010. Neither this delay, nor the December 5, 2008, final rule remove any regulatory requirement for owners or operators of facilities in operation before August 16, 2002, to maintain an SPCC Plan in accordance with the SPCC regulations.</p>
                <p>In November 2009, EPA promulgated revisions to the December 2008 amendments. EPA either retained or provided minor technical corrections for the majority of the December 2008 provisions. EPA removed provisions that excluded farms and oil production facilities from the loading/unloading rack requirements, exempted certain produced water containers at oil production facilities, and provided alternative qualified facilities eligibility criteria for oil production facilities.</p>
                <br />
                <h3>Which provisions finalized in the December 2008 final rule will become effective on January 14, 2010, without further modification?</h3>
                <p>Exemptions for hot mixed asphalt (HMA) and HMA containers, pesticide application equipment and related mix containers, and heating oil containers at single-family residences, including those located at farms;</p>
                <p>Amended definition of "facility" to clarify the existing flexibility associated with describing a facility's boundaries;</p>
                <p>Amended facility diagram requirements to provide additional flexibility;</p>
                <p>New definition of "loading/unloading rack" to clarify the oil transfer equipment subject to the provisions for facility tank car and tank truck loading/unloading racks, as well as amended provisions for this equipment;</p>
                <p>Amended general secondary containment requirements to provide more clarity;</p>
                <p>Exemption of non transportation related tank trucks from the sized secondary containment requirements;</p>
                <p>Amended security requirements;</p>
                <p>Amended integrity testing requirements to allow greater flexibility in the use of industry standards;</p>
                <p>Amended integrity testing requirements for containers that store animal fats and vegetable oils (AFVOs) and meet certain criteria;</p>
                <p>Amended definition of "production facility";</p>
                <p>Clarification that drilling and workover activities are not subject to the provisions at §112.9;</p>
                <p>Exemption for certain intra-facility gathering lines at oil production facilities from the SPCC requirements;</p>
                <p>More prescriptive requirements for a flowline/intra-facility gathering line maintenance program for all production facilities and an alternative compliance option for flowlines and intra-facility gathering lines for contingency planning in lieu of all secondary containment;</p>
                <p>Alternative compliance option for flow-through process vessels at oil production facilities to comply with the general secondary containment requirements and additional oil spill prevention measures in lieu of the sized secondary containment requirements;</p>
                <p>Definition of "produced water container" and alternative compliance measures for these containers which require general secondary containment, a process or procedure certified by a professional engineer (PE) designed to remove free-phase oil on the surface of the produced water in these containers and compliance with additional oil spill prevention measures in lieu of sized secondary containment requirements;</p>
                <p>Clarification of the definition of "permanently closed" as it applies to an oil production facility;</p>
                <p>and Technical corrections provided in the December 5, 2008.</p>
                <br />
                <h3>Which provisions finalized in the December 2008 final rule were removed?</h3>
                <p>Exclusions for oil production facilities and farms from loading/unloading rack requirements.</p>
                <p>Alternative qualified facility eligibility criteria for an oil production facility.</p>
                <p>Exemption for certain produced water containers.</p>
            </div>
        </div>
    )
}

export default Projects;