import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const STIPTanks = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Time Saving Tips</h2>
                <p>If a site is protected with a rectifier, one can usually check the tanks by touching a dispenser. This is possible because a rectifier connected to vent pipes or product pipes or the tanks will be connected to the dispensers via the electric wires or conduit or electric grounds. If a protected reading is measured using the dispensers as a contact point, it will be representative of the reading taken from the tank bottom. One can confirm continuity between the tank and the dispenser by measuring a low resistance between the dispenser and the rectifier negative connection. This eliminates the need to lift up a steel cover in the pavement.</p>
                <p>Low current outputs in a rectifier indicate coated tanks. Very low current outputs indicate ungrounded tanks. High current output of 5 or more amps indicates much underground metal at a site. The accuracy of the readings over and around the tanks and dispensers can be improved by pouring a small amount of water where the readings are taken. Where concrete and asphalt cover the tanks, take readings at cracks or expansion joints in the pavement. If oil and gas have been spilled on the pavement, do not trust the readings you get at those locations.</p>
                <p>If using a long lead wire to measure the resistance between the rectifier negative and another structure, measure the resistance of the wire and subtract that from the total resistance reading. Contact to the bottom of a tank can be made using a dipstick and a wire connected to a nail, a screw or even a paperclip. People will run over you! When working in the vicinity of the tank, wear orange vests and set up safety cones. Always keep one eye on the traffic and listen to engines when they are started.</p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/corrosionbasics">01</a></li>
                        <li><a href="/ust/understandingcathodicprotection">02</a></li>
                        <li><a href="/ust/interpretingvoltage">03</a></li>
                        <li><a href="/ust/electricalgrounding">04</a></li>
                        <li><a href="/ust/steelpipe">05</a></li>
                        <li><a href="/ust/fiberglasslinedtanks">06</a></li>
                        <li><a href="/ust/stiptanks">07</a></li>
                        <li><a href="/ust/steelswingjoints">08</a></li>
                        <li className="active"><a href="/ust/timesavingtips">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/steelswingjoints"><FontAwesomeIcon icon={faAngleLeft} /> Prev </a></li>
                        <li><a href="/UST"><FontAwesomeIcon icon={faAngleRight} />Home</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default STIPTanks;