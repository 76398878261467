import React from "react";
import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import EducationRoutes from "../Routes/EducationRoutes";
import IrrigationRoutes from "../Routes/IrrigationRoutes";
import InstallationRoutes from "../Routes/InstallationRoutes";
import PropaneRoutes from "../Routes/PropaneRoutes";
import OilGasRoutes from "../Routes/OilGasRoutes";
import PotableRoutes from "../Routes/PotableRoutes";
import USTRoutes from "../Routes/USTRoutes";
import MappingRoutes from "../Routes/MappingRoutes";

import ContactUsRoutes from "../pages/Contact/index.jsx";
import MeetTheTeam from "../pages/Contact/MeetTheTeam.jsx";
import ChipetaWebinar from "../pages/2020ChipetaPage/2020ChipetaWebinar.jsx";
import ChipetaResources from "../pages/2020ChipetaPage/2020ChipetaResources.jsx";
import PressResources from "../pages/2020WhitepaperPage/index.jsx";
import FairfaxTheatre from "../pages/FairfaxTheatre/index.jsx";
import PageNotFound from "../pages/404Page";
//import LoginPage from '../Routes/LoginPage'
//import RegisterPage from '../Routes/RegisterPage'
//import ForgotPage from '../pages/ForgotPage'

import company from "../data/company";
import withTracker from "./withTracker";

const Layout = (props) => {
    const { homeComponent } = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>{company.name}</title>
                <meta name="description" content={company.fullName} />
            </Helmet>
            <div>
                <Switch>
                    {/**
                     *
                     * HOME PAGE
                     *
                     */}
                    <Route
                        exact
                        path="/"
                        component={withTracker(homeComponent)}
                    />

                    {/**
                     *
                     * SERVICE PAGES
                     *
                     */}
                    <Route
                        path="/propane"
                        component={withTracker(PropaneRoutes)}
                    />
                    <Route
                        path="/oilgas"
                        component={withTracker(OilGasRoutes)}
                    />
                    <Route
                        path="/potablewater"
                        component={withTracker(PotableRoutes)}
                    />
                    <Route
                        path="/education"
                        component={withTracker(EducationRoutes)}
                    />
                    <Route
                        path="/irrigation"
                        component={withTracker(IrrigationRoutes)}
                    />
                    <Route path="/ust" component={withTracker(USTRoutes)} />
                    <Route
                        path="/mapping"
                        component={withTracker(MappingRoutes)}
                    />
                    <Route
                        path="/education"
                        component={withTracker(EducationRoutes)}
                    />
                    <Route
                        path="/installations"
                        component={withTracker(InstallationRoutes)}
                    />
                    <Route
                        path="/contactus"
                        component={withTracker(ContactUsRoutes)}
                    />
                    <Route
                        path="/meettheteam"
                        component={withTracker(MeetTheTeam)}
                    />
                    <Route
                        path="/2020chipetawebinar"
                        component={withTracker(ChipetaWebinar)}
                    />
                    <Route
                        path="/2020chipetaresources"
                        component={withTracker(ChipetaResources)}
                    />
                    <Route
                        path="/press-release"
                        component={withTracker(PressResources)}
                    />
                    <Route
                        path="/fairfaxtheatre"
                        component={withTracker(FairfaxTheatre)}
                    />

                    {/**
                     *
                     * AUTHENTICATION
                     *
                     */}
                    {/* <Route exact path='/login' component={LoginPage} />
                    <Route path='/register' component={RegisterPage} />
                    <Route path='/forgot' component={ForgotPage} /> */}

                    {/**
                     *
                     * DASHBOARDS
                     *
                     */}
                    {/*
                    <AdminRoute path='/admin/users' component={UserManager} />
                    <AdminRoute path='/admin/user/add' component={AddUser} />
                    <AdminRoute path='/admin/user/update/:userId' component={UpdateUser} />


                    <AdminRoute path='/admin/categories' component={CategoryManager} />
                    <AdminRoute path='/admin/category/add' component={AddCategory} />
                    <AdminRoute path='/admin/category/update/:categoryId' component={UpdateCategory} />


                    <AdminRoute path='/admin/companies' component={CompanyManager} />
                    <AdminRoute path='/admin/company/add' component={AddCompany} />
                    <AdminRoute path='/admin/company/update/:companyId' component={UpdateCompany} />


                    <AdminRoute path='/admin/locations' component={LocationManager} />
                    <AdminRoute path='/admin/location/add' component={AddLocation} />
                    <AdminRoute path='/admin/location/update/:locationId' component={UpdateLocation} />

                    <AdminRoute path='/admin/records' component={RecordManager} />

                    <AdminRoute path='/admin/record/add' component={AddRecord} />
                    <AdminRoute path='/admin/record/update/:recordId' component={UpdateRecord} />
                    */}
                    <Route component={withTracker(PageNotFound)} />
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default Layout;
