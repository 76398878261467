import React, { Component } from "react";
import { Link } from "react-router-dom";

class MobileMenu extends Component {
    state = {
        active: false,
    };

    toggleMobileMenu = () => {
        this.setState({
            active: !this.state.active,
        });
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    componentDidMount() {
        var offCanvasNav = document.getElementById("offcanvas-navigation");
        var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

        for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
            offCanvasNavSubMenu[i].insertAdjacentHTML(
                "beforebegin",
                "<span class='menu-expand'><i></i></span>"
            );
        }
        var menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
        var numMenuExpand = menuExpand.length;

        function sideMenuExpand() {
            if (this.parentElement.classList.contains("active") === true) {
                this.parentElement.classList.remove("active");
            } else {
                for (let i = 0; i < numMenuExpand; i++) {
                    menuExpand[i].parentElement.classList.remove("active");
                }
                this.parentElement.classList.add("active");
            }
        }

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", sideMenuExpand);
        }
    }

    render() {
        return (
            <div>
                {/*=======  offcanvas mobile menu  =======*/}
                <div
                    className={`offcanvas-mobile-menu ${
                        this.state.active ? "active" : ""
                    } `}
                    id="mobile-menu-overlay"
                >
                    <a
                        href="!#"   //NEW UPDATE
                        className="offcanvas-menu-close"
                        id="mobile-menu-close-trigger"
                        onClick={this.toggleMobileMenu}
                    >
                        <i
                            style={{ color: "#fff" }}
                            className="ion-android-close"
                        />
                    </a>
                    <div className="offcanvas-wrapper">
                        <div className="offcanvas-inner-content">
                            <div className="offcanvas-mobile-search-area"></div>
                            <nav
                                className="offcanvas-navigation"
                                id="offcanvas-navigation"
                            >
                                <ul>
                                    <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/`}>
                                            HOME
                                        </Link>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <Link to={`#`}>SERVICE</Link>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/propane`}
                                                >
                                                    PROPANE
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/oilgas`}
                                                >
                                                    OIL & GAS
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/potablewater`}
                                                >
                                                    POTABLE WATER STORAGE
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/irrigation`}
                                                >
                                                    IRRIGATION
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/ust`}
                                                >
                                                    UST
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/mapping`}
                                                >
                                                    GIS MAPPING
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <Link to={"#"}> EDUCATION </Link>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/education/understandingcorrosion`}
                                                >
                                                    UNDERSTANDING CORROSION
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/education/anodes`}
                                                >
                                                    ANODES
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/education/videos`}
                                                >
                                                    VIDEOS
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <Link to={"#"}> INSTALLATIONS </Link>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/installations/pipeline`}
                                                >
                                                    PIPELINES
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/installations/heatertreater`}
                                                >
                                                    HEATER TREATER AND MARINE
                                                    AQUEOUS APPLICATIONS
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/installations/ast`}
                                                >
                                                    ABOVEGROUND STORAGE TANKS
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/installations/ust`}
                                                >
                                                    UNDERGROUND STORAGE TANKS
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="https://anodesalesco.com">
                                            MATERIALS
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://cedam.mx">
                                            ARTIFACT CONSERVATION
                                        </a>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <Link to={"#"}> CONTACT </Link>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/contactus`}
                                                >
                                                    CONTACT US
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/meettheteam`}
                                                >
                                                    MEET THE TEAM
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                            <div className="offcanvas-widget-area">
                                <div className="off-canvas-contact-widget">
                                    <div className="header-contact-info">
                                        <ul className="header-contact-info__list">
                                            <li>
                                                <i className="ion-android-phone-portrait" />{" "}
                                                <a href="tel://19702434149">
                                                    (970) 243-4149{" "}
                                                </a>
                                            </li>
                                            <li>
                                                <i className="ion-android-mail" />{" "}
                                                <a href="mailto:info@anodesystems.com">
                                                    info@anodesystems.com
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/*Off Canvas Widget Social Start*/}
                                <div className="off-canvas-widget-social">
                                    <a
                                        href="//facebook.com/anodesystemsco"
                                        title="Facebook"
                                    >
                                        <i className="fa fa-facebook" />
                                    </a>
                                    <a
                                        href="//youtube.com/channel/UCIACRlEU0Lfz4vaM2vUqNbw"
                                        title="Youtube"
                                    >
                                        <i className="fa fa-youtube-play" />
                                    </a>
                                    <a
                                        href="//instagram.com/anodesystemscompany"
                                        title="Instagram"
                                    >
                                        <i className="fa fa-instagram" />
                                    </a>
                                </div>
                                {/*Off Canvas Widget Social End*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/*=======  End of offcanvas mobile menu  =======*/}
            </div>
        );
    }
}

export default MobileMenu;
