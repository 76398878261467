import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

class HeroSlider extends Component {
    render() {
        const params = {
            slidesPerView: 1,
            loop: true,
            speed: 1000,
            watchSlidesVisibility: true,
            effect: 'fade',
            navigation: {
                nextEl: '.ht-swiper-button-next',
                prevEl: '.ht-swiper-button-prev'
            },
            renderPrevButton: () => (
                <div className="ht-swiper-button-prev ht-swiper-button-nav d-none d-xl-block"><FontAwesomeIcon icon={faChevronLeft}/></div>
            ),
            renderNextButton: () => (
                <div className="ht-swiper-button-next ht-swiper-button-nav d-none d-xl-block"><FontAwesomeIcon icon={faChevronRight}/></div>
            ),
            autoplay: {
                delay: 5000,
            }
        }

        let data = [
            { bgImg: 'slider3.jpg', sliderTitle: 'Experienced Cathodic Protection Services', sliderSubtitle: 'We have been your go to solution for Cathodic Protection services since 1984.', btnLink: 'propane' },
            { bgImg: 'slider4.jpg', sliderTitle: 'Experienced Cathodic Protection Services', sliderSubtitle: 'We have been your go to solution for Cathodic Protection services since 1984.', btnLink: 'oilgas' }
        ];

        let DataList = data.map((val, i) => {
            return (
                <div className="swiper-slide" key={i}>
                    <div className="hero-slider__single-item" style={{ backgroundImage: `url(assets/img/slider/${val.bgImg})` }}>
                        <div className="hero-slider__content-wrapper">
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <div className="hero-slider__content m-auto text-center">
                                            <h2 className="hero-slider__title">{val.sliderTitle}</h2>
                                            <p className="hero-slider__text">{val.sliderSubtitle}</p>
                                            <Link className="hero-slider__btn hero-slider__btn--style2" to={`${val.btnLink}`}><strong> Learn More</strong> </Link>
                                            {/* <a className="hero-slider__btn hero-slider__btn--style2" href={`${process.env.PUBLIC_URL}/${val.btnLink}`}> GET STARTED NOW</a> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div >
            )
        });

        return (
            <div>
                {/*====================  hero slider area ====================*/}
                < div className="hero-alider-area" >
                    <Swiper {...params}>
                        {DataList}
                    </Swiper>
                </div >
                {/*====================  End of hero slider area  ====================*/}

            </div >
        )
    }
}

export default HeroSlider;