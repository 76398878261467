import React from 'react'
import { Row, Col } from 'react-bootstrap'

const PotableHome = () => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <img className="w-100" src="/assets/img/services/potable/potable1.jpg" alt="" />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h2 className="py-2 text-center">Potable Water Storage</h2>
                    <p>Anode Systems offers Cathodic Protection Services which include design, installation, and materials for water storage tanks or any kind of tank. Anode Systems is a trusted supplier since 1985.</p>
                    <p>Steel water storage tanks will corrode and leak if unprotected against corrosion from chlorinated water on the inside and from the earth on the outside. Anode Systems Company has developed an innovative solution to installing internal corrosion protection anode systems. Fourteen systems have been installed in Colorado, Utah and Wyoming at substantial cost savings to the water districts. The unique feature of the floating and floor mounted anodes is that they are not susceptible to damage from ice forming on the top of the water in the winter. Older systems in which anodes were hung from the ceiling either had to be removed in the fall or risk being pulled down by the ice in the winter. Either system takes only one or two days to install.</p>
                    <p>Anode Systems Company is engineering new and improved methods for cathodically protecting water storage tanks from corrosion. The three systems illustrated in the following examples are much cheaper to install than conventional systems. They can be leased to make the installation cost affordable to small water districts.</p>
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default PotableHome