import React from 'react';

const Pipeline = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Oil & Natural Gas Pipeline</h2>
                <img className="col-12 img-fluid" src="/assets/img/services/installations/pipeline.jpg" alt=""></img>
            </div>
        </div>
    )
}


export default Pipeline;