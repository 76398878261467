import React from "react";
import NavBar from "../../components/Navbar";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu";
import FairfaxGallery from "./components/FairfaxGallery";
import ArrowheadGallery from "./components/ArrowheadGallery";
import OilGallery from "./components/OilGallery";
import PennyGallery from "./components/PennyGallery";
import NickelGallery from "./components/NickelGallery";

const FairfaxTheatre = () => {
  return (
    <div>
      <NavBar />
      {/* breadcrumb */}
      {/*====================  breadcrumb area ====================*/}
      <div
        className="breadcrumb-area breadcrumb-bg"
        style={{
          backgroundImage: `url(assets/img/backgrounds/fairfax-bg.jpg)`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-banner text-center mt-5 mb-5">
                <h1>Fairfax Theatre Fundraiser</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}

      {/*====================  project details page content ====================*/}
      <div className="page-wrapper section-space--inner--120">
        {/*Projects section start*/}
        <div className="project-section ">
          <div className="container">
            <div className="row">
              <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                <div className="project-details">
                  <h2>Tall Chief Theatre</h2>
                  <p>
                    We are offering various items in exchange for donations to
                    the Tall Chief Theatre in Fairfax, OK.
                  </p>
                  <p>
                    Please find various images below illustrating the items that
                    we are giving in exchange for a donation
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <h3>Buffalo Nickel and Indian Head Penny Necklace</h3>
              <FairfaxGallery />
              <hr />
            </div>
            <div className="row">
              <h3>Arrowhead Necklace</h3>
              <ArrowheadGallery />
              <hr />
            </div>
            <div className="row">
              <h3>Sample of Oil</h3>
              <OilGallery />
              <hr />
            </div>
            <div className="row">
              <h3>Buffalo Nickel</h3>
              <NickelGallery />
              <hr />
            </div>
            <div className="row">
              <h3>Indian Head Penny</h3>
              <PennyGallery />
              <hr />
            </div>
          </div>
          <div className="container text-center">
            <div className="row">
              <p>
                <strong>
                  All proceeds go to the Fairfax Community Foundation
                </strong>
              </p>
              <hr />
              <p>
                <strong>501 3 (c) Tax ID:73-6280783</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*Projects section end*/}
      {/* Footer */}
      <Footer />

      {/* Mobile Menu */}
      <MobileMenu />
      {/*====================  End of project details page content  ====================*/}
    </div>
  );
};

export default FairfaxTheatre;
