import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row, Col } from 'react-bootstrap';
class ServiceTabTwo extends Component {

    render() {

        /* service tab menu */
        let serviceTabMenuData = [
            { iconName: 'flaticon-', tabMenuName: 'Pipelines' },
            { iconName: 'flaticon-', tabMenuName: 'Propane Tanks' },
            { iconName: 'flaticon-', tabMenuName: 'Irrigation' },
            { iconName: 'flaticon-', tabMenuName: 'Produced Water Tanks' },
            { iconName: 'flaticon-', tabMenuName: 'Potable Water Tanks' },
            { iconName: 'flaticon-', tabMenuName: 'Evaporative Coolers' }
        ];

        let serviceTabMenuDatalist = serviceTabMenuData.map((val, i) => {
            return (
                <Tab key={i}>  <i className={val.iconName}></i> <span className="text">{val.tabMenuName}</span></Tab>
            )
        });


        /* service tab content */

        let serviceTabContentData = [
            { bgUrl: "pipelines.jpg", contentTitle: 'Pipelines', contentDesc: 'Cathodic Protection (CP) is an important method of preventing corrosion on buried metal pipelines, but it must be applied at the correct level.  Every pipeline operator must carry out regular measurements of CP. ', serviceLink: 'oilgas' },
            { bgUrl: "propane.jpg", contentTitle: 'Propane Tanks', contentDesc: 'Protecting underground tanks from corrosion is easily achieved by the use of two commonly applied protection methods: external coating and cathodic protection.', serviceLink: 'propane' },
            { bgUrl: "irrigation.jpg", contentTitle: 'Irrigation', contentDesc: 'Metallic structures installed underground, on the ground, in water, or otherwise in contact with an electrolytic medium are subject to corrosion due to a chemical reaction with non-metallic elements in the surrounding medium.', serviceLink: 'irrigation' },
            { bgUrl: "produced.jpg", contentTitle: 'Produced Water Tanks', contentDesc: 'Steel water storage tanks are subject to corrosion on all of their external and internal surfaces.', serviceLink: 'oilgas/producedwatertankprogram' },
            { bgUrl: "potable.jpg", contentTitle: 'Potable Water Tanks', contentDesc: 'By design, all potable water storage tanks are inherently susceptible to corrosion attack.', serviceLink: 'potablewater' },
            { bgUrl: "evaporative.jpg", contentTitle: 'Evaporative Coolers', contentDesc: 'Our Zinc Anode is specifically designed to control rust and corrosion in metal Evaporative coolers. It functions just like the anode in your water heater. The Anode serves as a sacrificial metal that protects the metal cabinet.', serviceLink: '//thinkzincanode.com' },
        ];

        let serviceTabContentDatalist = serviceTabContentData.map((val, i) => {
            return (
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(assets/img/services/tabs/${val.bgUrl})` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.contentTitle}</h3>
                            <p className="service-tab__text">{val.contentDesc}</p>
                            <a href={`${process.env.PUBLIC_URL}/${val.serviceLink}`} className="see-more-link">SEE MORE</a>
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return (
            <div>
                <div className="service-tab-area section-space--inner--30 white-bg">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="section-title-area text-center section-space--bottom--20">
                                    <h2 style={{color:'#000'}} className="section-title">Our Specialties</h2>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="service-tab-wrapper">
                                    <Tabs>
                                        <Row className="no-gutters">
                                            <Col md={4}>
                                                <TabList>
                                                    <div className="service-tab__link-wrapper">
                                                        {serviceTabMenuDatalist}
                                                    </div>
                                                </TabList>
                                            </Col>
                                            <Col md={8}>
                                                {serviceTabContentDatalist}
                                            </Col>
                                        </Row>
                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default ServiceTabTwo;