import React, { Component } from 'react';

import NavBar from '../components/Navbar';
import MobileMenu from '../components/MobileMenu';

import InstallationSidebar from '../components/Sidebar/InstallationSidebar';

import BrandLogoSlider from '../pages/HomePage/BrandLogoSlider';
import Footer from '../components/Footer';
import { Switch, Route } from 'react-router-dom';
import Pipeline from '../pages/Services/installations/Pipeline';
import HeaterTreater from '../pages/Services/installations/HeaterTreater';
import UST from '../pages/Services/installations/UST';
import AST from '../pages/Services/installations/AST';
import { Container, Row, Col } from 'react-bootstrap';

class InstallationRoutes extends Component {

    render() {
        const { match } = this.props
        return (
            <div>
                <NavBar />
                <div className="breadcrumb-area">
                    <Container>
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#0">Services</a></li>
                                        <li>Installation</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--60">
                    {/*Service section start*/}
                    <div className="service-section">
                        <Container>
                            <Row>
                                <div className="col-lg-4 col-12 order-1 order-lg-1">
                                    <InstallationSidebar />
                                </div>
                                <div className="col-lg-8 col-12 order-2 order-lg-2">
                                    <Switch>
                                        <Route exact path={`${match.path}/pipeline`} component={Pipeline} />
                                        <Route exact path={`${match.path}/heatertreater`} component={HeaterTreater} />
                                        <Route exact path={`${match.path}/ast`} component={AST} />
                                        <Route exact path={`${match.path}/ust`} component={UST} />
                                    </Switch>
                                </div>
                            </Row>
                        </Container>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Brand logo */}
                <BrandLogoSlider background="grey-bg" />

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}


export default InstallationRoutes;