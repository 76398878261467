import React from 'react';
import { Row, Col } from 'react-bootstrap'

const WhatIsElectricalIsolation = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">What Is Dielectric Union And Electrical Isolation?</h2>
                <p>Recall that corrosion is an electrical release of energy in the form of electrons and d.c. current from a metal surface when it is in contact with soil. If an insulating barrier can be applied to block the flow of electrons along a metal surface, then the corrosion process can be stopped. It acts as a resistor to reduce the flow of electric current from a metal surface to the earth. This property of a material being a barrier to the flow of electrons along a metal surface or electric current from the surface into the earth is called its "dielectric strength."</p>
                <div className="col-8 offset-2 project-image py-3"><img src="/assets/img/services/education/whatiselectricalisolation.jpg" className="img-fluid" alt="" /></div>
                <p>All metals have a unique voltage stored within them that is related to the amount of energy used to create them. When two different metals such as copper and steel are connected together in the earth, the voltage difference between them will cause an electric current to flow through the earth from the metal with the higher voltage to the metal with a lower voltage. At the same time electrons flow on the metal from the steel to the copper. This is called a "bimetallic or dissimilar metal corrosion cell."</p>
                <p>In order to prevent copper piping from corroding steel pipe or tanks, dielectric unions are inserted between them to separate and to electrically isolate the copper pipe from the steel pipe or tank. Dielectric unions are the perfect solution for breaking the connection between the two metals. Underground steel propane tanks and steel piping must be electrically isolated from the buried copper water piping to the water heater and the copper electric grounds at homes and businesses. Unions are easily installed at the connection between a copper pipe and a steel pipe and near a regulator or gas meter.</p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">

                        <li><a href="/propane/whatiscorrosion">01</a></li>
                        <li><a href="/propane/whatmakessoilcorrosive">02</a></li>
                        <li><a href="/propane/whatiscathodicprotection">03</a></li>
                        <li><a href="/propane/allaboutmagnesiumanodes">04</a></li>
                        <li><a href="/propane/awordaboutcoatings">05</a></li>
                        <li className="active"><a href="/propane/whatiselectricalisolation">06</a></li>
                        <li><a href="/propane/howtoinstallananode">07</a></li>
                        <li><a href="/propane/testingforcorrosionprotection">08</a></li>
                        <li><a href="/propane/references">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/propane/awordaboutcoatings"><i className="fa fa-angle-left" /> Prev</a></li>
                        <li><a href="/propane/howtoinstallananode"><i className="fa fa-angle-right" /> Next</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}


export default WhatIsElectricalIsolation;