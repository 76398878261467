import React, { Component } from 'react';

import NavBar from '../components/Navbar';
import MobileMenu from '../components/MobileMenu';
import PotableSidebar from '../components/Sidebar/PotableSidebar';
import BrandLogoSlider from '../pages/HomePage/BrandLogoSlider';
import Footer from '../components/Footer';
import 'react-image-gallery/styles/css/image-gallery.css'
import ServiceSidebar from '../components/Sidebar/ServiceSidebar';
import PotableHome from '../pages/Services/potablewater/PotableHome';
import Tank100K from '../pages/Services/potablewater/Tank100K';
import Tank1M from '../pages/Services/potablewater/Tank1M';
import Tank3M from '../pages/Services/potablewater/Tank3M';
import { Switch, Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

class PotableServiceDetails extends Component {
    render() {
        return (
            <div>
                {/* Navigation bar */}
                <NavBar />

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area ">
                    <Container>
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#0">Services</a></li>
                                        <li>Potable Water Storage Tanks</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--60">
                    {/*Service section start*/}
                    <div className="service-section">
                        <Container>
                            <Row>
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <Switch>
                                        <Route exact path={'/potablewater'} component={PotableHome} />
                                        <Route exact path={'/potablewater/100kgallon'} component={Tank100K} />
                                        <Route exact path={'/potablewater/1mgallon'} component={Tank1M} />
                                        <Route exact path={'/potablewater/3mgallon'} component={Tank3M} />
                                    </Switch>
                                </div>
                                <div className="text-center col-lg-4 col-12 order-2 order-lg-1">
                                    <PotableSidebar />
                                    <br />
                                    <ServiceSidebar />
                                </div>
                            </Row>
                        </Container>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Brand logo */}
                <BrandLogoSlider background="grey-bg" />

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div >
        )
    }
}


export default PotableServiceDetails;