import React from "react";

export default class MyForm extends React.Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            status: "",
        };
    }

    render() {
        const { status } = this.state;
        return (
            <form
                onSubmit={this.submitForm}
                action="https://formspree.io/xqkydevp"
                method="POST"
            >
                {status === "SUCCESS" ? (
                    <p>Thanks, we will be back to you as soon as possible!</p>
                ) : (
                    <React.Fragment>
                        <label>Name:</label>
                        <input type="text" name="name" />
                        <label>Email:</label>
                        <input type="email" name="email" />
                        <label>Message:</label>
                        <input type="text" name="message" />
                        <button className="btn-primary">Submit</button>
                    </React.Fragment>
                )}
                {status === "ERROR" && <p>Ooops! There was an error.</p>}
            </form>
        );
    }

    submitForm(ev) {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({ status: "SUCCESS" });
            } else {
                this.setState({ status: "ERROR" });
            }
        };
        xhr.send(data);
    }
}
