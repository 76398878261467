import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap'

import NavBar from '../components/Navbar';
import MobileMenu from '../components/MobileMenu';

import ServiceSidebar from '../components/Sidebar/ServiceSidebar';

import IrrigationServiceGallery from '../pages/Services/irrigation/IrrigationServiceGallery';
import IrrigationPhotoGallery from '../pages/Services/irrigation/IrrigationPhotoGallery';

import BrandLogoSlider from '../pages/HomePage/BrandLogoSlider';
import Footer from '../components/Footer';

class IrrigationRoutes extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <div className="breadcrumb-area">
                    <Container>
                        <Row>
                            <Col>
                                <div className="page-banner text-center">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#0">Services</a></li>
                                        <li>Irrigation</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--60">
                    {/*Service section start*/}
                    <div className="service-section">
                        <Container>
                            <Row>
                                <div className="col-lg-4 col-12 order-1 order-lg-1">
                                    <ServiceSidebar />
                                </div>
                                <div className="col-lg-8 col-12 order-2 order-lg-2">
                                    <div className="service-details">
                                        {/* service gallery */}
                                        <IrrigationServiceGallery />
                                        <div className="content section-space--top--30">
                                            <Row>
                                                <Col xs={12}>
                                                    <h2 className="text-center py-2">Irrigation Solutions</h2>
                                                    <p>A large number of parameters affect pipe corrosion in water distribution systems, including water quality and composition, flow conditions, biological activity and corrosion inhibitors.</p>
                                                    <p>Here at Anode Systems we have spent the last 30 years helping many local Irrigation companies solve corrosion issues plaguing their irrigation systems. Give us a call today and see how we can you with your Corrosion Issues.</p>
                                                </Col>
                                                <div className="col-lg-4 col-12 section-space--top--30">
                                                    <h3>Why Use Anodes?</h3>
                                                    <ul>
                                                        <li>Cut Maintenance Expense</li>
                                                        <li>Cut Replacement Costs</li>
                                                        <li>Decrease Labor Costs</li>
                                                        <li>Stop Rust and Corrosion</li>
                                                        <li>Less Expensive Than Coatings</li>
                                                        <li>Easy To Install</li>
                                                    </ul>

                                                </div>
                                                <div className="col-lg-4 col-12 section-space--top--30">
                                                    <h3>How Do Anodes Work?</h3>
                                                    <ul>
                                                        <li>Same effect as galvanizing</li>
                                                        <li>Stop rust electrolytically</li>
                                                        <li>Works like a battery</li>
                                                        <li>Provide electrical charge</li>
                                                        <li>Provide electrons to iron</li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4 col-12 section-space--top--30">
                                                    <h3>Who Uses Anodes?</h3>
                                                    <ul>
                                                        <li>Grand Valley Irrigation District</li>
                                                        <li>Palisade Irrigation District</li>
                                                        <li>Bureau of Reclamation</li>
                                                        <li>Grand Valley Water Users Association</li>
                                                        <li>Redlands Water & Power Company</li>
                                                        <li>Water Districts & Gas Utilities</li>
                                                        <li>Underground Tank Owners</li>
                                                    </ul>
                                                </div>
                                            </Row>
                                        </div>
                                        <div className="content section-space--top--30">
                                            <Row>
                                                <div className="col-12 section-space--top--30">
                                                    <IrrigationPhotoGallery />
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                    {/*Service section end*/}
                </div>

                {/* Brand logo */}
                <BrandLogoSlider background="grey-bg" />

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />

            </div>
        )
    }
}


export default IrrigationRoutes;