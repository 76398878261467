import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap'

class ProjectImageGallery extends Component {
    render() {
        return (
            <div>
                {/*====================  project gallery area ====================*/}
                <div className='project-gallery-area section-space--inner--30 grey-bg'>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                {/* section title */}
                                <div className="section-title-area text-center section-space--bottom--20">
                                    <h2 style={{color:'#000'}} className="section-title">Latest Projects</h2>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="project-gallery-wrapper">
                                    <Row>
                                        <Col md={6}>
                                            <div className="single-gallery-project">
                                                <div className="single-gallery-project__image">
                                                    <img src="assets/img/projects/clifton.jpg" className="img-fluid" alt="" />
                                                </div>
                                                <div className="single-gallery-project__content">
                                                    <h4 className="title">CLIFTON WATER TANKS</h4>
                                                    {/* <Link to="/project-details">SEE MORE</Link> */}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={3} className="col-mobile-6">
                                            <div className="single-gallery-project">
                                                <div className="single-gallery-project__image">
                                                    <img src="assets/img/projects/arrowhead.jpg" className="img-fluid" alt="" />
                                                </div>
                                                <div className="single-gallery-project__content">
                                                    <h4 className="title">ARROWHEAD REGIONAL MEDICAL CENTER</h4>
                                                    {/* <Link to="/project-details">SEE MORE</Link> */}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={3} className="col-mobile-6">
                                            <div className="single-gallery-project">
                                                <div className="single-gallery-project__image">
                                                    <img src="assets/img/projects/project7.jpg" className="img-fluid" alt="" />
                                                </div>
                                                <div className="single-gallery-project__content">
                                                    <h4 className="title">PROJECT 7 WATER TANKS</h4>
                                                    {/* <Link to="/project-details">SEE MORE</Link> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*====================  End of project gallery area  ====================*/}
            </div>
        )
    }
}

export default ProjectImageGallery;
