import React from 'react';
import { Row, Col } from 'react-bootstrap'

const UnderstandingCorrosion = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">Corrosion Protection Videos</h2>
                <Row>
                    <Col xs={12}>
                        <h2>Corrosion in Action - Part 1</h2>
                        <iframe title="Video1" className="w-100" style={{ height: '400px' }} src="https://www.youtube.com/embed/5bFpT_oSz04?rel=0" allowfullscreen=""></iframe>
                    </Col>
                    <br />
                    <Col xs={12}>
                        <h2>Corrosion in Action - Part 2</h2>
                        <iframe title="Video2" className="w-100" style={{ height: '400px' }} src="https://www.youtube.com/embed/_Wc8EeCxYEg?rel=0" allowfullscreen=""></iframe>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


export default UnderstandingCorrosion;