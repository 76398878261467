import React from 'react';
import { Row, Col } from 'react-bootstrap'

const STIPTanks = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">STIP Tanks</h2>
                <div className="col-10 offset-1 project-image py-3"><img src="/assets/img/services/ust/UST-5.jpg" className="img-fluid" alt="" /></div>
                <p>There are several reasons why low voltage readings are measured on STIP tanks. The most often cause is from metal conduit that rubs against the edge of the riser bung. Over time, the conduit rubs the coating off the sharp edge of the bung and makes metal to metal contact causing an electrical short.</p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/corrosionbasics">01</a></li>
                        <li><a href="/ust/understandingcathodicprotection">02</a></li>
                        <li><a href="/ust/interpretingvoltage">03</a></li>
                        <li><a href="/ust/electricalgrounding">04</a></li>
                        <li><a href="/ust/steelpipe">05</a></li>
                        <li><a href="/ust/fiberglasslinedtanks">06</a></li>
                        <li className="active"><a href="/ust/stiptanks">07</a></li>
                        <li><a href="/ust/steelswingjoints">08</a></li>
                        <li><a href="/ust/timesavingtips">09</a></li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li><a href="/ust/fiberglasslinedtanks"><i className="fa fa-angle-left" /> Prev</a></li>
                        <li><a href="/ust/steelswingjoints"><i className="fa fa-angle-right" /> Next</a></li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default STIPTanks;