import React from 'react';

const ProducedWaterTankProgram = () => {

    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h4>February 8, 2010</h4>
                <br />
                <h4>From: Hans Schmoldt, Corrosion Engineer & Cathodic Protection Specialist</h4>
                <h4>To: SPCC Plan Administrators</h4>
                <br />
                <h4>Subject: Anode Systems' Produced Water Tank Corrosion Prevention Program</h4>
                <br />
                <h4>Dear Administrator:</h4>
                <br />
                <p>In January of this year a new SPCC rule became effective. The amended SPCC regulations no longer exempt produced water tanks at oil production facilities. This engineer interprets the new regulation to include gas production facilities if they produce an oil or gas liquid as a by product of natural gas production. If you believe, as I do, that the intent of the new rule is to prevent spills from produced water tanks caused by corrosion, take time to read on. Attached are “talking points” on the subject of corrosion of produced water tanks, the costs to the oil and gas industry from corrosion, the benefits of cathodic protection of oil and gas production facilities, milestones in the evolution of the EPA rules that govern the oil and gas industry and facts worth noting.</p>
                <br />
                <p>Leaking tanks are nothing new. What is new, is the recognition by the EPA that leaking tanks pose a problem to public health and the environment. Allowing oily salt water to spill into the ground or into ground water through holes in a tank's bottom is not allowed any more. Furthermore, it's not good business nor is it a responsible business practice.</p>
                <br />
                <p>For years, corrosion has been controlled and stopped with anodes in a process called cathodic protection. Anodes are used in heater treaters, heat exchangers, water tanks, water condensers, water trucks, petroleum products tanks and in domestic water heater tanks. Anodes are required by Federal law on underground or on grade steel fuel storage tanks. Anodes are required by Federal law on oil and gas transmission and distribution pipelines. The fact is, cathodic protection has been proven to stop corrosion of steel tanks and pipes.</p>
                <br />
                <p>Call or e-mail us for a cost estimate to bring your facilities up to code and more importantly, to protect your investment in your produced water facilities. We are NACE certified and have the experience necessary to design, install, maintain, repair and upgrade any cathodic protection you have or need.</p>
                <br />
                <p>Yours truly,</p>
                <img src="/assets/img/hans.png" alt="" />
                <p><strong>Hans Schmoldt</strong></p>
                <p><strong>NACE Cathodic Protection Specialist #4162</strong></p>
            </div>
        </div>
    )
}

export default ProducedWaterTankProgram;