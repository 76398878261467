import React from 'react';
import { Row, Col } from 'react-bootstrap';

const AWordAboutCoatings = () => {
    return (
        <div className="col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15 order-2">
            <div className="project-details">
                <h2 className="py-5 text-center">A Word About Coatings</h2>
                <p>
                    All steel objects are painted to keep them from rusting. The
                    paint is a waterproof barrier between the steel surface and
                    moisture in the air. Similarly, underground tanks need a
                    waterproof coating to protect them from corrosive
                    groundwater. Ordinary paint coatings do not hold up under
                    constant contact with mineralized moisture in the earth.
                    Specialized coatings are needed to withstand the corrosive
                    environment underground and provide a barrier between the
                    steel surface and air, water and even electricity without
                    deteriorating over time.
                </p>
                <div className="project-image col-8 offset-2 py-3">
                    <img
                        src="/assets/img/services/education/awordaboutcoatings.jpg"
                        className="img-fluid"
                        alt=""
                    />
                </div>
                <p>
                    The first line of defense against corrosion on an
                    underground tank is a good coating. Paint and coating
                    companies make a variety of coatings for a vast array of
                    applications. It is important to apply those that will hold
                    up over time to constant attack by water, oxygen, carbon
                    dioxide, mild acids, mechanical abrasion from rocks, earth
                    movements, roots, bacteria and other biological organisms as
                    they feed on organic compounds in them. They must be applied
                    correctly or they will not adhere to the steel surface. The
                    tank manufacturer often applies a tough, chemical and water
                    resistant coating at the factory. Factory applied coatings
                    include baked on powder paint, sprayed on chemically cured
                    epoxy resins, electrostatically applied fusion bond epoxy
                    and sprayed on coal tar or petroleum based enamel. Field
                    applied coatings are usually brush applied enamels, mastics,
                    polyurethane, reinforced plastics or epoxies. If you have an
                    underground tank or are planning to install one and can
                    scratch off the coating with a pocket knife, it will not
                    prevent corrosion underground. You should apply a second
                    coating made for underwater or underground use. If you are
                    located far from a large metropolitan area and do not have
                    the luxury of applying one of the above, you may find one
                    made to resist water in stock at a local boating supply
                    store.
                </p>
                <p>
                    Coatings alone, will not protect a buried tank against
                    corrosion. They are scratched, scraped and gouged during
                    handling, setting, backfilling and settling. They can have
                    small imperfections or missed spots here and there. Some
                    deteriorate underground. Corrosion pitting rates are greater
                    at these flaws, scratches and gaps in the coating than they
                    are on completely bare tanks. Cathodic protection with
                    anodes is needed to protect the steel wherever it is
                    exposed.
                </p>
            </div>
            <Row>
                <Col>
                    <ul className="page-pagination section-space--top--30">
                        <li>
                            <a href="/propane/whatiscorrosion">01</a>
                        </li>
                        <li>
                            <a href="/propane/whatmakessoilcorrosive">02</a>
                        </li>
                        <li>
                            <a href="/propane/whatiscathodicprotection">03</a>
                        </li>
                        <li>
                            <a href="/propane/allaboutmagnesiumanodes">04</a>
                        </li>
                        <li className="active">
                            <a href="/propane/awordaboutcoatings">05</a>
                        </li>
                        <li>
                            <a href="/propane/whatiselectricalisolation">06</a>
                        </li>
                        <li>
                            <a href="/propane/howtoinstallananode">07</a>
                        </li>
                        <li>
                            <a href="/propane/testingforcorrosionprotection">
                                08
                            </a>
                        </li>
                        <li>
                            <a href="/propane/references">09</a>
                        </li>
                    </ul>
                    <ul className="page-pagination section-space--top--30">
                        <li>
                            <a href="/propane/allaboutmagnesiumanodes">
                                <i className="fa fa-angle-left" /> Prev
                            </a>
                        </li>
                        <li>
                            <a href="/propane/whatiselectricalisolation">
                                <i className="fa fa-angle-right" /> Next
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
    );
};

export default AWordAboutCoatings;
