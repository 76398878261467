import React from 'react'
import ProducedServiceGallery from './ProducedServiceGallery'
import { Row, Col } from 'react-bootstrap'

const PropaneHome = () => {
    return (
        <React.Fragment>
            <div className="service-details">
                <div className="content section-space--top--30">
                    <Row>
                        <Col xs={12}>
                            <ProducedServiceGallery />
                            <h2 className="text-center py-4">Oil & Gas</h2>
                            <p>At Anode Systems Company we are dedicated to combining “boots on the ground” experience with the latest in technology to assist our clients as much as possible. The following brief example shows how such technology can save time and money. After completing a recent pipeline survey for a customer, we utilized existing knowledge of the geography with newly available technology to provide a “virtual tour” of the pipeline. This allowed us to identify areas of concern and document survey results in an easy to understand format, saving time and money and providing better, more accurate information for the client.</p>
                            <iframe title="Virtual_Tour" width="100%" height="500px" src="https://www.youtube.com/embed/_ZeomXDXIys" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </Col>
                        <div className="col-lg-4 col-12 section-space--top--30">
                            <h3>Produced Water Tanks</h3>
                            <p>Steel water storage tanks are subject to corrosion on all of their external and internal surfaces. We can provide Services to protect your investments.</p>
                        </div>
                        <div className="col-lg-4 col-12 section-space--top--30">
                            <h3>Pipelines</h3>
                            <p>Cathodic Protection (CP) is an important method of preventing corrosion on buried metal pipelines, but it must be applied at the correct level. Every pipeline operator must carry out regular measurements of CP.</p>
                        </div>
                        <div className="col-lg-4 col-12 section-space--top--30">
                            <h3>Colorado Flowline Compliance Services</h3>
                            <p>Are you ready for the new Colorado Oil & Gas Conservation Commission (COGCC) flowline compliance regulations? </p>
                        </div>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default PropaneHome