import React from 'react';
import { Link } from 'react-router-dom'

const EducationSidebar = (props) => {

    const links = [
        { url: "/education", icon: 'fa fa-question', title: 'Home' },
        { url: "/education/understandingcorrosion", icon: 'fa fa-question', title: 'Understanding Corrosion' },
        { url: "/education/anodes", icon: 'fa fa-question', title: 'Anodes' },
        { url: "/education/videos", icon: 'fa fa-question', title: 'Videos' },
    ].map((link) => {
        return (
            <li key={link.url}>
                <Link to={link.url}><i className={link.icon} />{link.title}</Link></li>
        )
    })

    return (
        <div>
            <div className="sidebar-wrapper">
                <div className="sidebar">
                    <h3 className="sidebar-title">FAQS</h3>
                    <ul className="sidebar-list">
                        {links}
                    </ul>
                </div>
            </div>
        </div>
    )
}


export default EducationSidebar;