import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery'
import { Row, Col } from 'react-bootstrap'

import 'react-image-gallery/styles/css/image-gallery.css'

class Tank100K extends Component {
    render() {

        const images = [
            {
                original: '/assets/img/services/potable/100k_1.jpg',
                description: 'Easterly view of the tank and conduit recently installed on the roof.'
            },
            {
                original: '/assets/img/services/potable/100k_2.jpg',
                description: 'South view of a 100,000 gallon underground tank showing a conduit in which the interior anode header is routed to the rectifier.'
            },
            {
                original: '/assets/img/services/potable/100k_3.jpg',
                description: 'Two anodes were installed inside the tank in PVC holders. One anode was suspended in the water by floats. The other was clamped to steel bracing.',
            },
            {
                original: '/assets/img/services/potable/100k_4.jpg',
                description: 'Each PVC holder has holes drilled in it so that the electric current can flow from the anode to the tank wall and supports.'
            },
            {
                original: '/assets/img/services/potable/100k_5.jpg',
                description: 'View of second anode eight feet deep in the PVC holder.'
            },
            {
                original: '/assets/img/services/potable/100k_6.jpg',
                description: 'View of anode eight feet deep suspended by two floats.'
            },

        ]
        return (

            <div className="col-12 order-1 order-lg-2">
                <div className="service-details">
                    {/* service gallery */}
                    {/* <PotableServiceGallery /> */}


                    <ImageGallery items={images} />
                    <div className="content section-space--top--30">
                        <Row>
                            <Col xs={12}>
                                <h2 className="py-2 text-center">100,000 Gallon Potable Water Storage Tanks</h2>
                                <p>Anode Systems offers Cathodic Protection Services which include design, installation, and materials for water storage tanks or any kind of tank. Anode Systems is a trusted supplier since 1985.</p>
                                <p>Steel water storage tanks will corrode and leak if unprotected against corrosion from chlorinated water on the inside and from the earth on the outside. Anode Systems Company has developed an innovative solution to installing internal corrosion protection anode systems. Fourteen systems have been installed in Colorado, Utah and Wyoming at substantial cost savings to the water districts. The unique feature of the floating and floor mounted anodes is that they are not susceptible to damage from ice forming on the top of the water in the winter. Older systems in which anodes were hung from the ceiling either had to be removed in the fall or risk being pulled down by the ice in the winter. Either system takes only one or two days to install.</p>
                                <p>Anode Systems Company is engineering new and improved methods for cathodically protecting water storage tanks from corrosion. The three systems illustrated in the following examples are much cheaper to install than conventional systems. They can be leased to make the installation cost affordable to small water districts.</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}


export default Tank100K;